import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const FibonacciTimeZonesChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;
  
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const candleSeries = chart.addCandlestickSeries();
  candleSeries.setData(data);

  const fibonacciSequence = [1, 2, 3, 5, 8, 13, 21, 34, 55, 89];
  const startIndex = Math.floor(data.length * 0.1); // Start from 10% of the data

  const minValue = Math.min(...data.map(d => d.low));
  const maxValue = Math.max(...data.map(d => d.high));

  fibonacciSequence.forEach((fib, index) => {
    if (startIndex + fib < data.length) {
      const fibSeries = chart.addLineSeries({
        color: `rgba(76, 175, 80, ${1 - index * 0.1})`,
        lineWidth: 1,
        lineStyle: 2,
      });
      
      const fibTime = data[startIndex + fib].time;
      
      fibSeries.setData([
        { time: fibTime, value: minValue },
        { time: fibTime + 1, value: maxValue } // Add 1 to ensure unique time values
      ]);
    }
  });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        Fibonacci Time Zones are a series of vertical lines based on the Fibonacci sequence. These lines are used to identify potential areas where significant price movement may occur. The zones are spaced according to the Fibonacci sequence (1, 2, 3, 5, 8, 13, 21, 34, etc.), with each number representing a specific number of bars or candles from a starting point. Traders use Fibonacci Time Zones to anticipate potential reversal points or breakouts in price action. The theory suggests that significant price movements are more likely to occur near these Fibonacci-based time intervals. However, like all technical indicators, Fibonacci Time Zones should be used in conjunction with other analysis tools and not relied upon exclusively for trading decisions.
      </p>
    </div>
  );
};

export default FibonacciTimeZonesChart;