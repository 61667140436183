import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const HelpIndicators = () => {
  const indicators = [
    {
      name: 'RSI (Relative Strength Index)',
      description: 'RSI is a momentum oscillator that measures the speed and change of price movements.',
      usage: 'Values above 70 indicate overbought conditions, while values below 30 indicate oversold conditions. Use these levels to identify potential reversal points.',
    },
    {
      name: 'MACD (Moving Average Convergence Divergence)',
      description: 'MACD is a trend-following momentum indicator that shows the relationship between two moving averages of a security\'s price.',
      usage: 'Look for crossovers between the MACD line and the signal line. A MACD crossing above the signal line is a bullish signal, while crossing below is bearish.',
    },
    {
      name: 'Bollinger Bands',
      description: 'Bollinger Bands consist of a middle band with two outer bands that expand and contract based on volatility.',
      usage: 'Price touching the upper band may indicate overbought conditions, while touching the lower band may indicate oversold conditions. A squeeze (narrowing of the bands) can indicate a potential breakout.',
    },
    {
      name: 'Moving Averages',
      description: 'Moving averages smooth out price data to create a single flowing line, helping to identify the direction of the trend.',
      usage: 'Common uses include identifying trend direction, support and resistance levels, and potential buy or sell signals when prices cross above or below the moving average.',
    },
    {
      name: 'Stochastic Oscillator',
      description: 'The Stochastic Oscillator is a momentum indicator that shows the location of the close relative to the high-low range over a set number of periods.',
      usage: 'Values above 80 are considered overbought, while values below 20 are considered oversold. Look for divergences between the indicator and price for potential reversal signals.',
    },
    {
      name: 'TD Sequential',
      description: 'TD Sequential is a technical indicator that identifies potential price exhaustion points and trend reversals.',
      usage: 'Look for buy signals when the countdown reaches 13 in a downtrend, and sell signals when it reaches 13 in an uptrend. Combine with other indicators for confirmation.',
    },
    {
      name: 'VWAP (Volume Weighted Average Price)',
      description: 'VWAP is an indicator that shows the average price weighted by volume over a specific time period.',
      usage: 'Use VWAP to identify intraday trends and potential support/resistance levels. Prices above VWAP may indicate bullish sentiment, while prices below may indicate bearish sentiment.',
    },
    {
      name: 'DMI (Directional Movement Index)',
      description: 'DMI is a technical indicator that identifies the direction of a price trend.',
      usage: 'Use the DMI to determine trend strength and direction. When the +DI line is above the -DI line, it indicates an uptrend, and vice versa. ADX values above 25 indicate a strong trend.',
    },
    {
      name: 'Support and Resistance Levels',
      description: 'Support and resistance levels are price points on a chart where the price tends to stop and reverse.',
      usage: 'Use these levels to identify potential entry and exit points. A break above resistance or below support can signal a potential trend change or continuation.',
    },
    {
      name: 'Fibonacci Retracement',
      description: 'Fibonacci retracement levels are horizontal lines that indicate where support and resistance are likely to occur.',
      usage: 'Use these levels to identify potential reversal points during a trend. Common Fibonacci levels are 23.6%, 38.2%, 50%, 61.8%, and 78.6%.',
    },
    {
      name: 'ATR (Average True Range)',
      description: 'ATR is a technical analysis indicator that measures market volatility by decomposing the entire range of an asset price for that period.',
      usage: 'Use ATR to gauge the level of market volatility. Higher ATR values indicate higher volatility, which can be useful for setting stop-loss orders or identifying potential breakout points.',
    },
    {
      name: 'OBV (On-Balance Volume)',
      description: 'OBV is a technical trading momentum indicator that uses volume flow to predict changes in stock price.',
      usage: 'Use OBV to confirm price trends. If OBV is rising while the price is falling, it could indicate that the price may soon reverse upwards.',
    },
  ];

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-2">Indicators Help</h2>
      <Link to="/help" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Help Center</Link>
      <div className="w-full max-w-3xl mx-auto px-2 sm:px-0">
        {indicators.map((indicator) => (
          <Disclosure as="div" key={indicator.name} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>{indicator.name}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <h3 className="font-medium text-gray-900 mb-2">Description</h3>
                  <p className="mb-4">{indicator.description}</p>
                  <h3 className="font-medium text-gray-900 mb-2">How to Use</h3>
                  <p>{indicator.usage}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default HelpIndicators;