import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WalletInput = ({ onSubmit }) => {
  const [wallet, setWallet] = useState('');
  const navigate = useNavigate();

  const handleViewHLVault = () => {
    navigate('/dashboard', { 
      state: { 
        vaultMode: true,
        vaults: [
          '0xdfc24b077bc1425ad1dea75bcb6f8158e10df303',
          '0x010461c14e146ac35fe42271bdc1134ee31c703a',
          '0x31ca8395cf837de08b24da3f660e77761dfb974b'
        ],
        initialActiveTab: 'Liquidity Vault'
      }
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/dashboard?wallet=${wallet}`);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md">
        <div className="mb-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="Enter wallet address"
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
          />
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between">
          <button 
            type="submit" 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto mb-2 sm:mb-0"
          >
            Search
          </button>
          <button 
            type="button" 
            onClick={handleViewHLVault}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
          >
            View HL Vaults
          </button>
        </div>
      </form>
    </div>
  );
};

export default WalletInput;