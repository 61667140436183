import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const HelpCharts = () => {
  const charts = [
    {
      name: 'Price Chart',
      description: 'The Price Chart displays the historical price movement of the selected asset using candlesticks.',
      usage: 'Use this chart to analyze price trends, support and resistance levels, and potential entry or exit points.',
    },
    {
      name: 'Volume Chart',
      description: 'The Volume Chart shows the trading volume for each time period, often displayed as a histogram below the price chart.',
      usage: 'Use this to confirm price movements and identify potential breakouts or reversals based on volume.',
    },
    {
      name: 'MACD Chart',
      description: 'The Moving Average Convergence Divergence (MACD) chart is a trend-following momentum indicator.',
      usage: 'Look for crossovers of the MACD line and signal line to identify potential buy or sell signals.',
    },
    {
      name: 'RSI Chart',
      description: 'The Relative Strength Index (RSI) is a momentum oscillator that measures the speed and change of price movements.',
      usage: 'Use RSI to identify overbought or oversold conditions, typically using 70 and 30 as thresholds.',
    },
    {
      name: 'Stochastic RSI Chart',
      description: 'The Stochastic RSI is an indicator of an indicator, measuring RSI relative to its high-low range over a period of time.',
      usage: 'Use it to identify overbought and oversold conditions, as well as potential reversal points.',
    },
    {
      name: 'DMI Chart',
      description: 'The Directional Movement Index (DMI) identifies the direction of a price trend.',
      usage: 'Use the DMI to determine trend strength and direction, with ADX values above 25 indicating a strong trend.',
    },
    {
      name: 'Bollinger Bands Chart',
      description: 'Bollinger Bands consist of a middle band (usually a simple moving average) and two outer bands that expand and contract based on volatility.',
      usage: 'Use these bands to identify potential reversals when price touches the upper or lower band, or breakouts when price moves outside the bands.',
    },
    {
      name: 'TD Sequential Chart',
      description: 'The TD Sequential is a technical indicator that identifies potential price exhaustion points and trend reversals.',
      usage: 'Look for buy signals when the countdown reaches 13 in a downtrend, and sell signals when it reaches 13 in an uptrend.',
    },
    {
      name: 'VWAP/MA Chart',
      description: 'This chart combines the Volume Weighted Average Price (VWAP) with various Moving Averages (MA).',
      usage: 'Use VWAP to identify intraday trends and support/resistance levels, and MAs to identify longer-term trends and potential crossover signals.',
    },
    {
      name: 'Fibonacci Levels Chart',
      description: 'Fibonacci levels are horizontal lines that indicate areas of support or resistance based on Fibonacci ratios.',
      usage: 'Use these levels to identify potential reversal points or price targets during trends or retracements.',
    },
    {
      name: 'Support and Resistance Levels Chart',
      description: 'This chart displays key price levels where the asset has historically found support (price stops falling) or resistance (price stops rising).',
      usage: 'Use these levels to identify potential entry and exit points, or areas where the price might reverse or breakout.',
    },
    {
      name: 'Divergence Chart',
      description: 'The Divergence Chart highlights instances where the price movement diverges from the movement of an indicator (usually RSI or MACD).',
      usage: 'Use divergences to identify potential trend reversals or continuations, especially when price makes a new high/low but the indicator doesn\'t.',
    },
    {
      name: 'Channel Pattern Chart',
      description: 'This chart identifies and displays price channels, which are parallel lines that contain the price movement over a period of time.',
      usage: 'Use channels to identify the overall trend direction and potential support and resistance levels. Look for breakouts from the channel for potential trend changes.',
    },
    {
      name: 'Liquidity Swings Chart',
      description: 'The Liquidity Swings Chart identifies areas of high trading activity or liquidity in the market.',
      usage: 'Use this chart to identify potential support and resistance levels based on areas of high liquidity, and to understand where large traders might be entering or exiting positions.',
    },
    {
      name: 'Head and Shoulders Pattern Chart',
      description: 'This chart identifies potential head and shoulders patterns, which are reversal patterns consisting of three peaks, with the middle peak (head) being the highest.',
      usage: 'Use this chart to identify potential trend reversals. A break below the neckline in a head and shoulders pattern could signal a bearish reversal, while an inverse head and shoulders could signal a bullish reversal.',
    },
  ];

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-2">Charts Help</h2>
      <Link to="/help" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Help Center</Link>
      <div className="w-full max-w-3xl mx-auto px-2 sm:px-0">
        {charts.map((chart) => (
          <Disclosure as="div" key={chart.name} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>{chart.name}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <h3 className="font-medium text-gray-900 mb-2">Description</h3>
                  <p className="mb-4">{chart.description}</p>
                  <h3 className="font-medium text-gray-900 mb-2">How to Use</h3>
                  <p>{chart.usage}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default HelpCharts;