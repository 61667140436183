// utils/formatNumber.js

export const formatNumber = (number, szDecimals, actualDecimals) => {
  if (number === null || number === undefined) return 'N/A';
  const precision = Math.max(szDecimals, actualDecimals, 0);
  return Number(number).toFixed(precision);
};

export const formatNumberWithSpotCheck = (number, szDecimals, isSpot) => {
  if (typeof number !== 'number') return null;
  const maxDecimals = isSpot ? 8 : 6;
  const allowedDecimals = Math.min(maxDecimals - (szDecimals || 0), 5);
  return Number(number.toFixed(allowedDecimals));
};