import React, { useState, useEffect, useMemo } from 'react';
import { 
  useReactTable, 
  getCoreRowModel, 
  getSortedRowModel, 
  getFilteredRowModel, 
  getPaginationRowModel,
  flexRender
} from '@tanstack/react-table';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { formatNumber } from '../utils/formatNumber';

const PerpsData = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('https://api.hyperliquid.xyz/info', {
          type: 'metaAndAssetCtxs'
        });
        const universe = response.data[0].universe;
        const assetCtxs = response.data[1];
        const combinedData = assetCtxs.map((asset, index) => ({
          ...asset,
          ...universe[index],
          percentChange: ((asset.markPx - asset.prevDayPx) / asset.prevDayPx) * 100,
          actualDecimals: (asset.markPx.toString().split('.')[1] || '').length
        }));
        setData(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Asset',
        accessorKey: 'name',
        cell: ({ getValue }) => (
          <Link to={`/coin/${getValue()}`} className="text-blue-500 hover:underline">
            {getValue()}
          </Link>
        ),
      },
      {
        header: 'Mark Price',
        accessorKey: 'markPx',
        cell: ({ row }) => formatNumber(row.original.markPx, row.original.szDecimals, row.original.actualDecimals),
      },
      {
        header: 'Exchange Price',
        accessorKey: 'midPx',
        cell: ({ row }) => formatNumber(row.original.midPx, row.original.szDecimals, row.original.actualDecimals),
      },
      {
        header: 'Oracle Price',
        accessorKey: 'oraclePx',
        cell: ({ row }) => formatNumber(row.original.oraclePx, row.original.szDecimals, row.original.actualDecimals),
      },
      {
        header: "Yesterday's Price",
        accessorKey: 'prevDayPx',
        cell: ({ row }) => formatNumber(row.original.prevDayPx, row.original.szDecimals, row.original.actualDecimals),
      },
      {
        header: '% Change',
        accessorKey: 'percentChange',
        cell: ({ getValue }) => (
          <span className={getValue() >= 0 ? 'text-green-600' : 'text-red-600'}>
            {getValue().toFixed(2)}%
          </span>
        ),
      },
      {
        header: 'Funding Rate',
        accessorKey: 'funding',
        cell: ({ getValue }) => formatNumber(getValue(), 6, 6),
      },
      {
        header: 'Open Interest',
        accessorKey: 'openInterest',
        cell: ({ getValue, row }) => `${formatNumber(getValue(), row.original.szDecimals, row.original.actualDecimals)} ${row.original.name}`,
      },
      {
        header: '24h Volume',
        accessorKey: 'dayNtlVlm',
        cell: ({ getValue }) => `$${formatNumber(getValue(), 2, 2)} USD`,
      },
      {
        header: 'Max Leverage',
        accessorKey: 'maxLeverage',
        cell: ({ getValue }) => `${getValue()}x`,
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">Perps Data</h2>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Filter by asset name"
          className="px-3 py-2 border rounded"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <span>
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? ' '}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="border px-4 py-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between items-center">
        <div>
          <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
            {'<<'}
          </button>
          <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
            {'<'}
          </button>
          <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
            {'>'}
          </button>
          <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
            {'>>'}
          </button>
        </div>
        <span>
          Page{' '}
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>{' '}
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
          className="px-2 py-1 border rounded"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default PerpsData;