import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const AccumulationDistributionLineChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateADL = (data) => {
      let adl = 0;
      return data.map(candle => {
        const moneyFlowMultiplier = ((candle.close - candle.low) - (candle.high - candle.close)) / (candle.high - candle.low);
        const moneyFlowVolume = moneyFlowMultiplier * candle.volume;
        adl += moneyFlowVolume;
        return { time: candle.time, value: adl };
      });
    };

    const adlData = calculateADL(data);

    const adlSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)',
      lineWidth: 2,
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    adlSeries.setData(adlData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        The Accumulation/Distribution Line (ADL) is a volume-based indicator designed to measure the cumulative flow of money into and out of a security. It assesses the supply and demand of a security by determining whether investors are generally accumulating (buying) or distributing (selling). The indicator is calculated by considering the position of each period's close relative to its high and low, and then multiplying this by the period's volume. A rising ADL indicates accumulation (buying pressure), while a falling ADL indicates distribution (selling pressure). Traders use the ADL to confirm trends identified by price movements and to spot potential price reversals. Divergences between the ADL and price can signal potential trend changes, with a rising ADL and falling price potentially indicating a bullish reversal, and vice versa.
      </p>
    </div>
  );
};

export default AccumulationDistributionLineChart;