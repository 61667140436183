import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';

const DivergenceChart = ({ data, rsiData, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0 || !rsiData || rsiData.length === 0) {
      console.log("Insufficient data for DivergenceChart");
      return;
    }

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        timeVisible: true,
        secondsVisible: false,
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    candleSeries.setData(data);

    const rsiSeries = chart.addLineSeries({
      color: 'purple',
      lineWidth: 2,
      priceScaleId: 'right',
    });

    const filteredRSIData = rsiData.filter(d => d.value !== null && d.value !== undefined);
    rsiSeries.setData(filteredRSIData);

    // Detect divergences
    const divergences = detectDivergences(data, filteredRSIData, timeframe);

    // Add markers for divergences
    if (divergences.length > 0) {
      candleSeries.setMarkers(
        divergences.map(d => ({
          time: d.time,
          position: d.type === 'bullish' ? 'belowBar' : 'aboveBar',
          color: d.type === 'bullish' ? '#26a69a' : '#ef5350',
          shape: d.type === 'bullish' ? 'arrowUp' : 'arrowDown',
          text: d.type === 'bullish' ? 'B' : 'S',
        }))
      );
    }

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, rsiData, timeframe, szDecimals, isSpot, actualDecimals]);

  return <div ref={chartContainerRef} />;
};

const detectDivergences = (priceData, rsiData, timeframe) => {
  const divergences = [];
  const lookbackPeriod = getLookbackPeriod(timeframe);
  const significanceThreshold = getSignificanceThreshold(timeframe);
  const minSwingDuration = getMinSwingDuration(timeframe);

  if (!Array.isArray(priceData) || !Array.isArray(rsiData) || 
      priceData.length < lookbackPeriod || rsiData.length < lookbackPeriod) {
    console.log("Insufficient data for divergence detection");
    return divergences;
  }

  const findSwings = (data, getValue) => {
    const swings = [];
    let isUpSwing = null;
    let swingStart = 0;
    let extremeValue = getValue(data[0]);
    let extremeIndex = 0;

    for (let i = 1; i < data.length; i++) {
      const currentValue = getValue(data[i]);
      if (isUpSwing === null) {
        isUpSwing = currentValue > extremeValue;
      }

      if ((isUpSwing && currentValue > extremeValue) || (!isUpSwing && currentValue < extremeValue)) {
        extremeValue = currentValue;
        extremeIndex = i;
      } else {
        const swingPercentage = Math.abs((currentValue - extremeValue) / extremeValue) * 100;
        if (swingPercentage >= significanceThreshold && (i - swingStart) >= minSwingDuration) {
          swings.push({
            startIndex: swingStart,
            endIndex: i - 1,
            extremeIndex: extremeIndex,
            extremeValue: extremeValue,
            isUpSwing: isUpSwing
          });
          swingStart = i - 1;
          isUpSwing = !isUpSwing;
          extremeValue = currentValue;
          extremeIndex = i;
        }
      }
    }
    return swings;
  };

  const priceSwings = findSwings(priceData, d => d.close);
  const rsiSwings = findSwings(rsiData, d => d.value);

  for (let i = 1; i < priceSwings.length; i++) {
    const priceSwing = priceSwings[i];
    const prevPriceSwing = priceSwings[i - 1];
    const correspondingRSISwing = rsiSwings.find(s => s.extremeIndex >= prevPriceSwing.extremeIndex && s.extremeIndex <= priceSwing.extremeIndex);

    if (correspondingRSISwing) {
      // Bullish divergence
      if (!priceSwing.isUpSwing && correspondingRSISwing.isUpSwing) {
        divergences.push({
          time: priceData[priceSwing.extremeIndex].time,
          type: 'bullish',
        });
      }
      // Bearish divergence
      else if (priceSwing.isUpSwing && !correspondingRSISwing.isUpSwing) {
        divergences.push({
          time: priceData[priceSwing.extremeIndex].time,
          type: 'bearish',
        });
      }
    }
  }

  return divergences;
};

const getLookbackPeriod = (timeframe) => {
  switch (timeframe) {
    case '1m': return 60;
    case '5m': return 48;
    case '15m': return 32;
    case '30m': return 24;
    case '1h': return 24;
    case '4h': return 14;
    case '1d': return 14;
    default: return 14;
  }
};

const getSignificanceThreshold = (timeframe) => {
  switch (timeframe) {
    case '1m': return 0.5;
    case '5m': return 1;
    case '15m': return 1.5;
    case '30m': return 2;
    case '1h': return 2.5;
    case '4h': return 3;
    case '1d': return 5;
    default: return 3;
  }
};

const getMinSwingDuration = (timeframe) => {
  switch (timeframe) {
    case '1m': return 5;
    case '5m': return 4;
    case '15m': return 3;
    case '30m': return 3;
    case '1h': return 2;
    case '4h': return 2;
    case '1d': return 2;
    default: return 3;
  }
};

export default DivergenceChart;