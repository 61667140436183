import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useTable, useSortBy, usePagination } from 'react-table';
import { ArrowUpIcon, ArrowDownIcon } from 'lucide-react';
import { useTab } from '../TabContext';

const VaultTab = ({ vaultData, vaultWebData, columns, filterInput, calculateROE, assetCtxs, formatCurrency, formatDifference, formatCurrencyDifference, previousDataRef }) => {
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    if (vaultWebData?.clearinghouseState?.assetPositions) {
      const newPositions = vaultWebData.clearinghouseState.assetPositions.map(position => {
        const coin = position.position.coin;
        const currentSize = parseFloat(position.position.szi);
        const currentPrice = Math.abs(currentSize * parseFloat(position.position.entryPx));

        const prevData = previousDataRef.current[coin] || {};
        const initialSize = prevData.initialSize !== undefined ? prevData.initialSize : currentSize;
        const initialPrice = prevData.initialPrice !== undefined ? prevData.initialPrice : currentPrice;

        const sziChange = currentSize - initialSize;
        const positionPriceChange = currentPrice - initialPrice;

        // Update previousDataRef with initial values and current values
        previousDataRef.current[coin] = {
          initialSize: initialSize,
          initialPrice: initialPrice,
          currentSize: currentSize,
          currentPrice: currentPrice,
        };

        return {
          ...position,
          positionPrice: currentPrice,
          type: currentSize >= 0 ? 'LONG' : 'SHORT',
          roe: calculateROE(position.position, assetCtxs[coin]),
          sziChange,
          positionPriceChange
        };
      });

      setPositions(newPositions);
    }
  }, [vaultWebData, calculateROE, assetCtxs, previousDataRef]);

  const filteredPositions = useMemo(() => {
    return positions.filter(position =>
      position.position.coin.toLowerCase().includes(filterInput.toLowerCase())
    );
  }, [positions, filterInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredPositions,
      initialState: { pageIndex: 0, pageSize: 20 },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  if (!vaultData || !vaultWebData) {
    return <div>Loading vault data...</div>;
  }

  const crossMarginSummary = vaultData.crossMarginSummary || {};
  const marginSummary = vaultData.marginSummary || {};
  const clearinghouseState = vaultWebData.clearinghouseState || {};

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="bg-white shadow rounded p-4">
          <h3 className="font-bold">Cross Margin Summary</h3>
          <p>Account Value: {formatCurrency(crossMarginSummary.accountValue) || 'N/A'}</p>
          <p>Total Margin Used: {formatCurrency(crossMarginSummary.totalMarginUsed) || 'N/A'}</p>
          <p>Total Ntl Pos: {formatCurrency(crossMarginSummary.totalNtlPos) || 'N/A'}</p>
          <p>Total Raw USD: {formatCurrency(crossMarginSummary.totalRawUsd) || 'N/A'}</p>
        </div>
        <div className="bg-white shadow rounded p-4">
          <h3 className="font-bold">Margin Summary</h3>
          <p>Account Value: {formatCurrency(marginSummary.accountValue) || 'N/A'}</p>
          <p>Total Margin Used: {formatCurrency(marginSummary.totalMarginUsed) || 'N/A'}</p>
          <p>Total Ntl Pos: {formatCurrency(marginSummary.totalNtlPos) || 'N/A'}</p>
          <p>Total Raw USD: {formatCurrency(marginSummary.totalRawUsd) || 'N/A'}</p>
        </div>
        <div className="bg-white shadow rounded p-4">
          <h3 className="font-bold">Withdrawable</h3>
          <p>{formatCurrency(vaultData.withdrawable) || 'N/A'}</p>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Live Data</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-white shadow rounded p-4">
            <h4 className="font-bold">Account Summary</h4>
            <p>Account Value: {formatCurrency(clearinghouseState.marginSummary?.accountValue) || 'N/A'}</p>
            <p>Total Notional: {formatCurrency(clearinghouseState.marginSummary?.totalNtlPos) || 'N/A'}</p>
            <p>Margin Usage: {formatCurrency(clearinghouseState.marginSummary?.totalMarginUsed) || 'N/A'}</p>
          </div>
          <div className="bg-white shadow rounded p-4">
            <h4 className="font-bold">Open Orders</h4>
            <p>Count: {vaultWebData.openOrders?.length || 'N/A'}</p>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Live Data - Open Positions</h3>
        <div className="overflow-x-auto">
          <table {...getTableProps()} className="min-w-full bg-white">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                      className="px-4 py-2"
                    >
                      {header.render('Header')}
                      <span>
                        {header.isSorted ? (header.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      const { key, ...cellProps } = cell.getCellProps();
                      return (
                        <td key={key} {...cellProps} className="border px-4 py-2 whitespace-nowrap">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex flex-wrap items-center justify-between">
          <div>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mr-2 px-2 py-1 border rounded">
              {'<<'}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-2 px-2 py-1 border rounded">
              {'<'}
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage} className="mr-2 px-2 py-1 border rounded">
              {'>'}
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="mr-2 px-2 py-1 border rounded">
              {'>>'}
            </button>
          </div>
          <span className="mr-4">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value));
            }}
            className="p-2 border rounded"
          >
            {[5, 10, 20, 30, 40, 50].map(size => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const ConsensusTab = ({ webData2, columns, filterInput, calculateROE, assetCtxs, formatCurrency, previousDataRef }) => {
  const [consensusData, setConsensusData] = useState([]);
  const [countdown, setCountdown] = useState(5);
  const webData2Ref = useRef(webData2);

  useEffect(() => {
    webData2Ref.current = webData2;
  }, [webData2]);

  useEffect(() => {
    const updateConsensusData = () => {
      const currentWebData2 = webData2Ref.current;
      if (Object.keys(currentWebData2).length === 0) return;

      const combinedPositions = Object.values(currentWebData2).reduce((acc, vaultData) => {
        if (vaultData.clearinghouseState && Array.isArray(vaultData.clearinghouseState.assetPositions)) {
          vaultData.clearinghouseState.assetPositions.forEach(position => {
            const coin = position.position.coin;
            const currentSize = parseFloat(position.position.szi);
            const entryPrice = parseFloat(position.position.entryPx);
            const liquidationPrice = parseFloat(position.position.liquidationPx);
            const positionValue = Math.abs(currentSize * entryPrice);
            const returnOnEquity = parseFloat(position.position.returnOnEquity);
            

            if (!acc[coin]) {
              acc[coin] = {
                entryPrice: entryPrice,
                liquidationPrice: liquidationPrice,
                returnOnEquity: returnOnEquity,
                totalSize: currentSize,
                totalValue: positionValue,
                weightedEntryPrice: entryPrice * Math.abs(currentSize),
                weightedLiquidationPrice: liquidationPrice * Math.abs(currentSize),
                positions: [position.position],
              };
            } else {
              acc[coin].entryPrice += entryPrice;
              acc[coin].liquidationPrice += liquidationPrice;
              acc[coin].returnOnEquity += returnOnEquity;
              acc[coin].totalSize += currentSize;
              acc[coin].totalValue += positionValue;
              acc[coin].weightedEntryPrice += entryPrice * Math.abs(currentSize);
              acc[coin].weightedLiquidationPrice += liquidationPrice * Math.abs(currentSize);
              acc[coin].positions.push(position.position);
            }
            //console.log('1 coin: ' + coin + ' - entry price: ' + entryPrice + ' - all entryprice: ' + acc[coin].entryPrice + ' - entry calculated: ' + entryPrice * Math.abs(currentSize))
          });
        }
        return acc;
      }, {});

      const processedData = Object.entries(combinedPositions).map(([coin, data]) => {
        const averageEntryPriceOLD = data.weightedEntryPrice / Math.abs(data.totalSize);
        const averageEntryPrice = data.entryPrice / Math.abs(3);
        const averageLiquidationPriceOLD = data.weightedLiquidationPrice / Math.abs(data.totalSize);
        const averageLiquidationPrice = data.liquidationPrice / Math.abs(3);
        const averageROE = data.returnOnEquity / Math.abs(3);
        
        const unrealizedPnl = data.positions.reduce((sum, pos) => sum + parseFloat(pos.unrealizedPnl), 0);

        const prevPosition = previousDataRef.current[coin] || {};
        const sziChange = data.totalSize - (prevPosition.totalSize || data.totalSize);
        const positionPriceChange = data.totalValue - (prevPosition.totalValue || data.totalValue);

        //console.log('2 coin: ' + coin + ' - avgEntry (right): ' + averageEntryPrice + ' - avgLiq (right): ' + averageLiquidationPrice)

        // Update previousDataRef
        previousDataRef.current[coin] = {
          totalSize: data.totalSize,
          totalValue: data.totalValue
        };

        // Determine if the position change is positive (green) or negative (red)
        const isPositiveChange = (data.totalSize < 0 && sziChange < 0) || (data.totalSize > 0 && sziChange > 0);

        // Use the leverage from assetCtxs
        const leverage = assetCtxs[coin] ? assetCtxs[coin].leverage : 1;

        return {
          position: {
            coin,
            szi: data.totalSize.toString(),
            entryPx: averageEntryPrice.toString(),
            liquidationPx: averageLiquidationPrice.toString(),
            returnOnEquity: averageROE.toString(),
            unrealizedPnl: unrealizedPnl.toString(),
            leverage: { value: leverage.toString() },
          },
          positionPrice: data.totalValue,
          type: data.totalSize >= 0 ? 'LONG' : 'SHORT',
          roe: calculateROE({
            returnOnEquity: averageROE.toString(),
          }, assetCtxs[coin]),
          sziChange,
          positionPriceChange,
          isPositiveChange
        };
      });

      setConsensusData(processedData);
    };

    // Initial update
    updateConsensusData();

    // Set up interval for updates
    const intervalId = setInterval(() => {
      updateConsensusData();
      setCountdown(5);
    }, 5000);

    // Set up countdown
    const countdownId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown > 0 ? prevCountdown - 1 : 5);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(countdownId);
    };
  }, [calculateROE, assetCtxs, previousDataRef]);

  const filteredData = useMemo(() => {
    return consensusData.filter(row =>
      row.position.coin.toLowerCase().includes(filterInput.toLowerCase())
    );
  }, [consensusData, filterInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 20 },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-2">
        Consensus Vault - Combined Positions
        <span className="ml-4 text-sm text-gray-500">
          This data will refresh in {countdown} seconds.
        </span>
      </h3>
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="min-w-full bg-white">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(header => (
                  <th
                    {...header.getHeaderProps(header.getSortByToggleProps())}
                    className="px-4 py-2"
                  >
                    {header.render('Header')}
                    <span>
                      {header.isSorted ? (header.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const { key, ...cellProps } = cell.getCellProps();
                    return (
                      <td key={key} {...cellProps} className="border px-4 py-2 whitespace-nowrap">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex flex-wrap items-center justify-between">
        <div>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="mr-2 px-2 py-1 border rounded">
            {'<<'}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className="mr-2 px-2 py-1 border rounded">
            {'<'}
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage} className="mr-2 px-2 py-1 border rounded">
            {'>'}
          </button>
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className="mr-2 px-2 py-1 border rounded">
            {'>>'}
          </button>
        </div>
        <span className="mr-4">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          className="p-2 border rounded"
        >
          {[5, 10, 20, 30, 40, 50].map(size => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [data, setData] = useState({});
  const [webData2, setWebData2] = useState({});
  const webData2Ref = useRef({});
  const [assetCtxs, setAssetCtxs] = useState({});
  const [filterInput, setFilterInput] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const wsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { activeTab, setActiveTab } = useTab();

  const liquidityVaultRef = useRef({});
  const aVaultRef = useRef({});
  const bVaultRef = useRef({});
  const consensusVaultRef = useRef({});

  const vaultMode = location.state?.vaultMode;
  const vaults = location.state?.vaults || [];
  const initialActiveTab = location.state?.initialActiveTab;
  const walletFromUrl = searchParams.get('wallet');

  const [wallet, setWallet] = useState(walletFromUrl || '');
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const FETCH_INTERVAL = 5000; // 5 seconds
  const [shouldFetch, setShouldFetch] = useState(true);

  useEffect(() => {
    if (walletFromUrl) {
      setWallet(walletFromUrl);
    }
  }, [walletFromUrl]);

  useEffect(() => {
    if (initialActiveTab && vaultMode) {
      setActiveTab(initialActiveTab);
    }
  }, [initialActiveTab, setActiveTab, vaultMode]);

  useEffect(() => {
    if (!wallet && !vaultMode) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      const now = Date.now();
      if (now - lastFetchTime < FETCH_INTERVAL) {
        return;
      }
    
      try {
        // Fetch metaAndAssetCtxs data regardless of mode
        const metaAndAssetCtxsResponse = await axios.post('https://api.hyperliquid.xyz/info', {
          type: 'metaAndAssetCtxs'
        });
    
        const universeData = metaAndAssetCtxsResponse.data[0].universe;
        const assetCtxsData = metaAndAssetCtxsResponse.data[1];
        const assetCtxsMap = {};
    
        universeData.forEach((asset, index) => {
          assetCtxsMap[asset.name] = {
            markPx: assetCtxsData[index].markPx,
            leverage: asset.maxLeverage // This will be used as the default leverage
          };
        });
    
        setAssetCtxs(assetCtxsMap);
    
        if (vaultMode) {
          const dataPromises = vaults.map(vault =>
            axios.post('https://api.hyperliquid.xyz/info', {
              type: 'clearinghouseState',
              user: vault
            })
          );
          const responses = await Promise.all(dataPromises);
          const newData = {};
          responses.forEach((response, index) => {
            newData[vaults[index]] = response.data;
          });
          setData(newData);
          
          const hasOpenPositions = Object.values(newData).some(
            vaultData => vaultData.clearinghouseState && 
                         Array.isArray(vaultData.clearinghouseState.assetPositions) &&
                         vaultData.clearinghouseState.assetPositions.length > 0
          );
          setShouldFetch(hasOpenPositions);
        } else {
          const response = await axios.post('https://api.hyperliquid.xyz/info', {
            type: 'clearinghouseState',
            user: wallet
          });
          setData(response.data);
          
          const hasOpenPositions = response.data.clearinghouseState && 
                                   Array.isArray(response.data.clearinghouseState.assetPositions) &&
                                   response.data.clearinghouseState.assetPositions.length > 0;
          setShouldFetch(hasOpenPositions);
        }
        setLastFetchTime(now);
    
        if (!shouldFetch) {
          console.log('No open positions. Stopping data fetching.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 429) {
          setTimeout(fetchData, FETCH_INTERVAL);
        }
      }
    };

    if (shouldFetch) {
      fetchData();
      const intervalId = setInterval(fetchData, FETCH_INTERVAL);
      return () => clearInterval(intervalId);
    }
  }, [vaultMode, vaults, navigate, wallet, lastFetchTime, shouldFetch]);

  const setupWebSocket = (address) => {
    const ws = new WebSocket('wss://api.hyperliquid.xyz/ws');
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('WebSocket connected');
      ws.send(JSON.stringify({
        method: 'subscribe',
        subscription: { type: 'webData2', user: address }
      }));
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.channel === 'webData2') {
        if (vaultMode) {
          webData2Ref.current = {
            ...webData2Ref.current,
            [address]: message.data
          };
          setWebData2(prevWebData2 => ({
            ...prevWebData2,
            [address]: message.data
          }));
        } else {
          webData2Ref.current = message.data;
          setWebData2(message.data);
        }
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.onclose = (event) => {
      console.log('WebSocket closed:', event);
    };

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  };

  useEffect(() => {
    const cleanupFunctions = vaultMode 
      ? vaults.map(setupWebSocket)
      : [setupWebSocket(wallet)];

    const heartbeat = setInterval(() => {
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({ method: 'ping' }));
      }
    }, 30000);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(heartbeat);
      cleanupFunctions.forEach(cleanup => cleanup());
      window.removeEventListener('resize', handleResize);
    };
  }, [vaultMode, vaults, wallet]);

  const calculateROE = useCallback((position, currentPrice) => {
    const returnOE = parseFloat(position.returnOnEquity);
    let roe = returnOE * 100
    return roe;
  }, []);

  const formatCurrency = (value, maxDecimals = 6) => {
    if (value === null || value === undefined) return '$0.00';
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return '$0.00';
    
    if (Math.abs(numValue) < 0.000001) {
      return numValue.toExponential(2);
    } else if (Math.abs(numValue) < 1) {
      return '$' + numValue.toFixed(maxDecimals).replace(/\.?0+$/, '');
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(numValue);
    }
  };

  const formatDifference = (current, previous, decimals = 2, isShort = false, isCurrency = false) => {
    if (previous === undefined) return null;
    
    let diff = current - previous;
    let isIncrease = diff > 0;
  
    // For short positions and position price, we reverse the logic
    if (isShort) {
      isIncrease = !isIncrease;
    }
  
    const formattedDiff = Math.abs(diff).toFixed(decimals);
    const color = isIncrease ? 'text-green-600' : 'text-red-600';
    const Arrow = isIncrease ? ArrowUpIcon : ArrowDownIcon;
    
    // Only show the difference if it's not zero
    if (diff !== 0) {
      return (
        <span className={`ml-2 ${color} flex items-center`}>
          <Arrow size={16} />
          {isCurrency ? '$' : ''}{isIncrease ? '+' : '-'}{formattedDiff}
        </span>
      );
    }
    
    return null;
  };

  const formatCurrencyDifference = (current, previous, decimals = 2) => {
    if (previous === undefined) return null;
    
    const diff = current - previous;
    const isIncrease = diff > 0;
    const formattedDiff = Math.abs(diff).toLocaleString('en-US', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
    
    const color = isIncrease ? 'text-green-600' : 'text-red-600';
    const Arrow = isIncrease ? ArrowUpIcon : ArrowDownIcon;
    
    if (diff !== 0) {
      return (
        <span className={`ml-2 ${color} flex items-center`}>
          <Arrow size={16} />
          {isIncrease ? '+$' : '-$'}{formattedDiff}
        </span>
      );
    }
    
    return null;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Coin',
        accessor: 'position.coin',
        sortType: (a, b) => a.original.position.coin.localeCompare(b.original.position.coin),
        Cell: ({ value }) => (
          <Link to={`/coin/${value}`} className="text-blue-500 hover:underline">
            {value}
          </Link>
        ),
      },
      {
        Header: 'Position',
        accessor: 'position.szi',
        sortType: (a, b) => parseFloat(a.original.position.szi) - parseFloat(b.original.position.szi),
        Cell: ({ value, row }) => {
          const formattedValue = parseFloat(value).toFixed(5);
          const change = row.original.sziChange;
          const isPositiveChange = row.original.isPositiveChange;
          return (
            <div className="flex items-center">
              {formattedValue}
              {change !== 0 && (
                <span className={`ml-2 ${isPositiveChange ? 'text-green-600' : 'text-red-600'}`}>
                  {isPositiveChange ? '▲' : '▼'} {Math.abs(change).toFixed(5)}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Entry Price',
        accessor: 'position.entryPx',
        sortType: 'number',
        Cell: ({ value }) => formatCurrency(value, 6),
      },
      {
        Header: 'Current Price',
        accessor: (row) => assetCtxs[row.position.coin]?.markPx,
        sortType: 'number',
        Cell: ({ value }) => formatCurrency(value, 6),
      },
      {
        Header: 'Position Price',
        accessor: 'positionPrice',
        sortType: 'number',
        Cell: ({ value, row }) => {
          const formattedValue = formatCurrency(value);
          const change = row.original.positionPriceChange;
          return (
            <div className="flex items-center">
              {formattedValue}
              {change !== 0 && (
                <span className={`ml-2 ${change > 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {change > 0 ? '▲' : '▼'} {formatCurrency(Math.abs(change))}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'type',
        sortType: 'basic',
        Cell: ({ value }) => (
          <span className={value === 'LONG' ? 'text-green-600' : 'text-red-600'}>
            {value}
          </span>
        ),
      },
      {
        Header: 'Liq Price',
        accessor: 'position.liquidationPx',
        sortType: 'number',
        Cell: ({ value }) => formatCurrency(value, 6),
      },
      {
        Header: 'ROE',
        accessor: 'roe',
        sortType: 'number',
        Cell: ({ value }) => {
          const formattedValue = value.toFixed(2) + '%';
          const colorClass = value >= 0 ? 'text-green-600' : 'text-red-600';
          return <span className={colorClass}>{formattedValue}</span>;
        },
      },
      {
        Header: 'Unrealized PNL',
        accessor: 'position.unrealizedPnl',
        sortType: (a, b) => parseFloat(a.original.position.unrealizedPnl) - parseFloat(b.original.position.unrealizedPnl),
        Cell: ({ value }) => {
          const formattedValue = formatCurrency(value);
          const colorClass = parseFloat(value) >= 0 ? 'text-green-600' : 'text-red-600';
          return <span className={colorClass}>{formattedValue}</span>;
        },
      },
    ],
    [assetCtxs, formatCurrency]
  );

  return (
    <div className="container mx-auto mt-28 p-4">
      <h2 className="text-2xl font-bold mb-4">
        {vaultMode ? 'Vault Dashboard' : (
          <>
            Dashboard for{' '}
            <span className="hidden md:inline">{wallet}</span>
            <span className="md:hidden">{wallet.slice(0, 6)}...{wallet.slice(-4)}</span>
          </>
        )}
      </h2>
      <input
        value={filterInput}
        onChange={e => setFilterInput(e.target.value)}
        placeholder="Filter by coin"
        className="mb-4 p-2 border rounded"
      />
      {vaultMode ? (
        <>
          {activeTab === 'Liquidity Vault' && (
            <VaultTab
              vaultData={data[vaults[0]]}
              vaultWebData={webData2[vaults[0]]}
              columns={columns}
              filterInput={filterInput}
              calculateROE={calculateROE}
              assetCtxs={assetCtxs}
              formatCurrency={formatCurrency}
              formatDifference={formatDifference}
              formatCurrencyDifference={formatCurrencyDifference}
              previousDataRef={liquidityVaultRef}
            />
          )}
          {activeTab === 'A Vault' && (
            <VaultTab
              vaultData={data[vaults[1]]}
              vaultWebData={webData2[vaults[1]]}
              columns={columns}
              filterInput={filterInput}
              calculateROE={calculateROE}
              assetCtxs={assetCtxs}
              formatCurrency={formatCurrency}
              formatDifference={formatDifference}
              formatCurrencyDifference={formatCurrencyDifference}
              previousDataRef={aVaultRef}
            />
          )}
          {activeTab === 'B Vault' && (
            <VaultTab
              vaultData={data[vaults[2]]}
              vaultWebData={webData2[vaults[2]]}
              columns={columns}
              filterInput={filterInput}
              calculateROE={calculateROE}
              assetCtxs={assetCtxs}
              formatCurrency={formatCurrency}
              formatDifference={formatDifference}
              formatCurrencyDifference={formatCurrencyDifference}
              previousDataRef={bVaultRef}
            />
          )}
          {activeTab === 'Consensus Vault' && (
            <ConsensusTab
              webData2={webData2}
              columns={columns}
              filterInput={filterInput}
              calculateROE={calculateROE}
              assetCtxs={assetCtxs}
              formatCurrency={formatCurrency}
              previousDataRef={consensusVaultRef}
            />
          )}
        </>
      ) : (
        <VaultTab
          vaultData={data}
          vaultWebData={webData2}
          columns={columns}
          filterInput={filterInput}
          calculateROE={calculateROE}
          assetCtxs={assetCtxs}
          formatCurrency={formatCurrency}
          formatDifference={formatDifference}
          formatCurrencyDifference={formatCurrencyDifference}
          previousDataRef={liquidityVaultRef}
        />
      )}
    </div>
  );
};

export default Dashboard;