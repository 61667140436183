// src/utils/fetchChartData.js
import axios from 'axios';
import { calculateIndicators, detectHeadAndShoulders } from './indicators';

async function fetchChartData(chartType, timeframe, coin) {
  try {
    // Fetch historical data
    const response = await axios.post('https://api.hyperliquid.xyz/info', {
      type: 'candleSnapshot',
      req: { coin: coin, interval: timeframe, startTime: Date.now() - 500 * getTimeframeInMilliseconds(timeframe), endTime: Date.now() }
    });

    // Format the data
    const formattedData = response.data.map(candle => ({
      time: Math.floor(candle.t / 1000),
      open: parseFloat(candle.o),
      high: parseFloat(candle.h),
      low: parseFloat(candle.l),
      close: parseFloat(candle.c),
      volume: parseFloat(candle.v)
    }));

    // Sort the data
    const sortedData = formattedData.sort((a, b) => a.time - b.time);

    // Calculate indicators
    const indicators = calculateIndicators(sortedData);

    // Detect patterns based on chart type
    let patterns = [];
    if (chartType === 'headandshoulders') {
      const result = detectHeadAndShoulders(sortedData);
      patterns = result.patterns;
    }
    // Add more pattern detection logic for other chart types as needed

    return {
      data: sortedData,
      indicators: indicators,
      patterns: patterns,
      chartType: chartType,
      timeframe: timeframe,
      coin: coin
    };

  } catch (error) {
    console.error('Error fetching chart data:', error);
    throw error;
  }
}

// Helper function to convert timeframe to milliseconds
function getTimeframeInMilliseconds(tf) {
  const units = {
    'm': 60 * 1000,
    'h': 60 * 60 * 1000,
    'd': 24 * 60 * 60 * 1000
  };
  const [value, unit] = tf.match(/(\d+)(\w)/).slice(1);
  return parseInt(value) * units[unit];
}

export default fetchChartData;