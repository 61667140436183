import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender
} from '@tanstack/react-table';

const OpenOrders = ({ wallet }) => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const navigate = useNavigate();

  // Add this function to truncate the wallet address
  const truncateAddress = (address) => {
    if (address.length <= 10) return address;
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    if (!wallet) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.post('https://api.hyperliquid.xyz/info', {
          type: 'frontendOpenOrders',
          user: wallet
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [wallet, navigate]);

  const columns = useMemo(
    () => [
      {
        header: 'Asset',
        accessorKey: 'coin',
      },
      {
        header: 'Side',
        accessorKey: 'side',
        cell: ({ getValue }) => {
          const side = getValue();
          return (
            <span className={side === 'B' ? 'text-green-600' : 'text-red-600'}>
              {side === 'B' ? 'BUY/LONG' : 'SELL/SHORT'}
            </span>
          );
        },
      },
      {
        header: 'Size',
        accessorKey: 'sz',
      },
      {
        header: 'Price',
        accessorKey: 'limitPx',
      },
      {
        header: 'Order ID',
        accessorKey: 'oid',
      },
    ],
    []
  );

  const bidsData = useMemo(() => data.filter(order => order.side === 'B'), [data]);
  const asksData = useMemo(() => data.filter(order => order.side === 'A'), [data]);

  const TableComponent = ({ data, title }) => {
    const table = useReactTable({
      data,
      columns,
      state: {
        globalFilter,
      },
      onGlobalFilterChange: setGlobalFilter,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        pagination: {
          pageSize: 10,
        },
      },
    });

    return (
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4">{title}</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <span>
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted()] ?? ' '}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id} className="border px-4 py-2">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-4 flex justify-between items-center">
          <div>
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
              {'<<'}
            </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
              {'<'}
            </button>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
              {'>'}
            </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()} className="mx-1 px-3 py-1 border rounded bg-white disabled:opacity-50">
              {'>>'}
            </button>
          </div>
          <span>
            Page{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>{' '}
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value));
            }}
            className="px-2 py-1 border rounded"
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  };

  if (!wallet) return null;
  if (!data) return <div>Loading...</div>;

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">Open Orders for <span className="hidden md:inline">{wallet}</span> <span className="md:hidden">{truncateAddress(wallet)}</span></h2>
      <div className="mb-4">
        <input
          type="text"
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value)}
          placeholder="Filter by asset name"
          className="px-3 py-2 border rounded"
        />
      </div>
      <TableComponent data={bidsData} title="Bids/Buys" />
      <TableComponent data={asksData} title="Asks/Sells" />
    </div>
  );
};

export default OpenOrders;