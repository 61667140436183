import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { calculateConsensusSignals } from '../utils/indicators';

const ConsensusChart = ({ data, indicators, volume, timeframe, szDecimals, isSpot, actualDecimals, onError, consensusSignals }) => {
  const chartContainerRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const candleSeriesRef = useRef(null);
  const [error, setError] = useState(null);
  const [noSignals, setNoSignals] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);

  const logError = useCallback((message, details = {}) => {
    console.error(message, details);
    setError(message);
    onError(message);
  }, [onError]);

  const initializeChart = useCallback(() => {
    if (chartContainerRef.current && !chartInstanceRef.current) {
      try {
        const newChart = createChart(chartContainerRef.current, {
          width: chartContainerRef.current.clientWidth,
          height: 400,
          layout: {
            backgroundColor: '#ffffff',
            textColor: 'rgba(33, 56, 77, 1)',
          },
          grid: {
            vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
            horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
          },
          crosshair: { mode: CrosshairMode.Normal },
          rightPriceScale: {
            borderColor: 'rgba(197, 203, 206, 1)',
            precision: Math.max(szDecimals || 0, actualDecimals || 0),
            minMove: 1 / Math.pow(10, Math.max(szDecimals || 0, actualDecimals || 0)),
          },
          timeScale: {
            borderColor: 'rgba(197, 203, 206, 1)',
            timeVisible: true,
            secondsVisible: false,
          },
        });
        chartInstanceRef.current = newChart;
      } catch (err) {
        logError("Failed to initialize chart", { error: err });
      }
    }
  }, [szDecimals, actualDecimals, logError]);

  useEffect(() => {
    initializeChart();
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.remove();
        chartInstanceRef.current = null;
        candleSeriesRef.current = null;
      }
    };
  }, [initializeChart]);

  useEffect(() => {
    const renderChart = () => {
      if (!chartContainerRef.current || !data || data.length === 0 || !chartInstanceRef.current) {
        return logError("Invalid chart data or initialization");
      }

      try {
        const chart = chartInstanceRef.current;

        if (candleSeriesRef.current) {
          chart.removeSeries(candleSeriesRef.current);
        }

        candleSeriesRef.current = chart.addCandlestickSeries({
          upColor: '#26a69a',
          downColor: '#ef5350',
          borderVisible: false,
          wickUpColor: '#26a69a',
          wickDownColor: '#ef5350',
        });

        candleSeriesRef.current.setData(data);

        const consensusSignals = calculateConsensusSignals(data, indicators, volume, timeframe);

        if (consensusSignals && consensusSignals.length > 0) {
          setNoSignals(false);
          setWarningMessage(null);
          const markers = consensusSignals.map(signal => ({
            time: signal.time,
            position: signal.type.includes('BUY') ? 'belowBar' : 'aboveBar',
            color: signal.type.includes('BUY') ? '#26a69a' : '#ef5350',
            shape: signal.type.includes('BUY') ? 'arrowUp' : 'arrowDown',
            text: signal.type,
            size: signal.type.includes('STRONG') ? 1 : 2,
          }));
  
          candleSeriesRef.current.setMarkers(markers);
        } else {
          setNoSignals(true);
          setWarningMessage("No Consensus signals generated. This could be due to insufficient data, low market volatility, or missing indicators.");
        }

        chart.timeScale().fitContent();
      } catch (err) {
        logError("An error occurred while creating the Consensus Chart", { error: err });
      }
    };

    renderChart();
  }, [data, consensusSignals, timeframe, logError]);

  useEffect(() => {
    const handleResize = () => {
      if (chartInstanceRef.current && chartContainerRef.current) {
        chartInstanceRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (error) {
    return (
      <div className="text-red-500 p-4 bg-red-100 border border-red-400 rounded">
        <h3 className="font-bold mb-2">Error:</h3>
        <p>{error}</p>
        <p className="mt-2">Please try refreshing the page. If the problem persists, contact support.</p>
      </div>
    );
  }

  return (
    <div>
      <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }} />
      {warningMessage && (
        <div className="text-yellow-700 p-4 bg-yellow-100 border border-yellow-400 rounded mt-4">
          <h3 className="font-bold mb-2">Warning:</h3>
          <p>{warningMessage}</p>
        </div>
      )}
      {noSignals && !warningMessage && (
        <div className="text-blue-700 p-4 bg-blue-100 border border-blue-400 rounded mt-4">
          <h3 className="font-bold mb-2">Information:</h3>
          <p>No Consensus signals were generated for the current data. This could be due to low volatility, the absence of clear trading patterns, or missing indicator data.</p>
        </div>
      )}
    </div>
  );
};

export default ConsensusChart;