import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateStochRSI } from '../utils/indicators';

const StochRSIChart = ({ data }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 200,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const stochRSIData = calculateStochRSI(data);

    const kSeries = chart.addLineSeries({ color: 'blue', lineWidth: 2 });
    const dSeries = chart.addLineSeries({ color: 'red', lineWidth: 2 });

    kSeries.setData(stochRSIData.k);
    dSeries.setData(stochRSIData.d);

    // Add horizontal lines for overbought and oversold levels
    const overboughtLine = chart.addLineSeries({ color: 'rgba(255, 0, 0, 0.5)', lineWidth: 1, lineStyle: 2 });
    const oversoldLine = chart.addLineSeries({ color: 'rgba(0, 255, 0, 0.5)', lineWidth: 1, lineStyle: 2 });

    overboughtLine.setData(stochRSIData.k.map(d => ({ time: d.time, value: 80 })));
    oversoldLine.setData(stochRSIData.k.map(d => ({ time: d.time, value: 20 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data]);

  return <div ref={chartContainerRef} />;
};

export default StochRSIChart;