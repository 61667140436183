import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const OnBalanceVolumeChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateOBV = (data) => {
      let obv = 0;
      return data.map((candle, index) => {
        if (index === 0) {
          return { time: candle.time, value: 0 };
        }
        if (candle.close > data[index - 1].close) {
          obv += candle.volume;
        } else if (candle.close < data[index - 1].close) {
          obv -= candle.volume;
        }
        return { time: candle.time, value: obv };
      });
    };

    const obvData = calculateOBV(data);

    const obvSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)',
      lineWidth: 2,
      priceScaleId: '',
      scaleMargins: {
        top: 0.1,
        bottom: 0.1,
      },
    });
    obvSeries.setData(obvData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        On-Balance Volume (OBV) is a momentum indicator that uses volume flow to predict changes in stock price. The OBV is a cumulative total of volume (positive and negative). There are three rules implemented in the calculation: 1) If today's closing price is higher than yesterday's closing price, then: Current OBV = Previous OBV + Today's Volume 2) If today's closing price is lower than yesterday's closing price, then: Current OBV = Previous OBV - Today's Volume 3) If today's closing price equals yesterday's closing price, then: Current OBV = Previous OBV. OBV shows crowd sentiment that can predict a bullish or bearish outcome. Traders use OBV to confirm price trends and spot potential reversals. When OBV is rising, it shows that buyers are willing to step in and push the price higher. When OBV is falling, it indicates that sellers are taking control and pushing the price lower.
      </p>
    </div>
  );
};

export default OnBalanceVolumeChart;