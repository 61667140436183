import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { createChart } from 'lightweight-charts';
import fetchChartData from '../utils/fetchChartData';

const ChartImagePage = () => {
  const chartContainerRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const chartCreatedRef = useRef(false);
  const location = useLocation();
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);

  const fetchDataAndRenderChart = useCallback(async () => {
    if (chartCreatedRef.current) return;

    const searchParams = new URLSearchParams(location.search);
    const chartType = searchParams.get('chart');
    const timeframe = searchParams.get('timeframe');
    const coin = searchParams.get('coin');

    try {
      console.log('Fetching chart data...');
      const data = await fetchChartData(chartType, timeframe, coin);
      console.log('Chart data fetched successfully');
      setChartData(data);

      if (!data || data.data.length === 0) {
        setError('No data available');
        return;
      }

      if (!chartInstanceRef.current && chartContainerRef.current) {
        console.log('Creating chart...');
        const chart = createChart(chartContainerRef.current, {
          width: 800,
          height: 400,
          layout: {
            background: { color: '#ffffff' },
            textColor: 'black',
          },
          grid: {
            vertLines: { color: '#e0e0e0' },
            horzLines: { color: '#e0e0e0' },
          },
          rightPriceScale: { borderColor: '#e0e0e0' },
          timeScale: { borderColor: '#e0e0e0' },
        });

        chartInstanceRef.current = chart;

        const candleSeries = chart.addCandlestickSeries();
        candleSeries.setData(data.data);

        if (chartType === 'headandshoulders') {
          data.patterns.forEach(pattern => {
            const lineSeries = chart.addLineSeries({ color: 'blue', lineWidth: 2 });
            lineSeries.setData([
              { time: pattern.left_shoulder.time, value: pattern.left_shoulder.price },
              { time: pattern.head.time, value: pattern.head.price },
              { time: pattern.right_shoulder.time, value: pattern.right_shoulder.price },
            ]);
          });
        }

        chart.timeScale().fitContent();
        chartCreatedRef.current = true;
        console.log('Chart created successfully');
      }
    } catch (err) {
      console.error('Error in fetchDataAndRenderChart:', err);
      setError('Failed to fetch or render chart data');
    }
  }, [location]);

  useEffect(() => {
    fetchDataAndRenderChart();

    return () => {
      if (chartInstanceRef.current) {
        console.log('Cleaning up chart...');
        chartInstanceRef.current.remove();
        chartInstanceRef.current = null;
        chartCreatedRef.current = false;
      }
    };
  }, [fetchDataAndRenderChart]);

  if (error) {
    return <div className="container mx-auto mt-28 p-4">Error: {error}</div>;
  }

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">
        {chartData ? `${chartData.coin} ${chartData.chartType} Chart (${chartData.timeframe})` : 'Loading...'}
      </h2>
      <div ref={chartContainerRef} />
    </div>
  );
};

export default ChartImagePage;