import React from 'react';

const MacdCrossoverSignal = ({ macdCrossover, formatTimeSinceCrossover }) => {
  const timeSinceCrossover = macdCrossover.time
    ? Math.floor((Date.now() - macdCrossover.time) / 60000)
    : null;

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8">
      <h3 className="text-xl font-semibold mb-2">Bullish or Bearish Crossover</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h4 className="text-sm font-semibold text-gray-500">MACD Signal</h4>
          <p className={`text-lg font-bold ${macdCrossover.signal === 'Bullish' ? 'text-green-600' : 'text-red-600'}`}>
            {macdCrossover.signal}
          </p>
        </div>
        <div>
          <h4 className="text-sm font-semibold text-gray-500">Crossover Time</h4>
          <p className="text-lg font-bold">
            {macdCrossover.time ? macdCrossover.time.toLocaleString() : 'N/A'}
          </p>
        </div>
        <div>
          <h4 className="text-sm font-semibold text-gray-500">Time Since Crossover</h4>
          <p className="text-lg font-bold">
            {timeSinceCrossover ? formatTimeSinceCrossover(timeSinceCrossover) : 'N/A'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MacdCrossoverSignal;