import React from 'react';

const IndicatorValues = ({ indicatorValues, szDecimals, isSpot }) => {
  const getLatestValue = (arr) => {
    if (Array.isArray(arr) && arr.length > 0) {
      const lastItem = arr[arr.length - 1];
      return lastItem.value !== undefined ? lastItem.value : lastItem;
    }
    return null;
  };

  const formatNumber = (num, additionalDecimals = 0) => {
    if (num === null || num === undefined) {
      console.log("formatNumber received null or undefined");
      return 'N/A';
    }
    const maxDecimals = isSpot ? 8 : 6;
    const allowedDecimals = Math.min(maxDecimals - (szDecimals || 0) + additionalDecimals, 5);
    //console.log("Formatting number:", num, "with allowed decimals:", allowedDecimals);
    return Number(num).toFixed(allowedDecimals);
  };

  const getLatestStochasticValue = (key) => {
    //console.log("Full stochastic data:", indicatorValues.stochastic);
    if (Array.isArray(indicatorValues.stochastic) && indicatorValues.stochastic.length > 0) {
      const latestStochastic = indicatorValues.stochastic[indicatorValues.stochastic.length - 1];
      //console.log(`Latest stochastic:`, latestStochastic);
      if (latestStochastic && typeof latestStochastic === 'object') {
        //console.log(`Stochastic keys:`, Object.keys(latestStochastic));
        if (key in latestStochastic) {
          const latestValue = latestStochastic[key];
          //console.log(`Latest ${key} value:`, latestValue);
          if (latestValue !== undefined && latestValue !== null) {
            const formattedValue = formatNumber(latestValue, 2);
            //console.log(`Formatted ${key} value:`, formattedValue);
            return formattedValue;
          }
        }
      }
    }
    return 'N/A';
  };
  
  

  if (!indicatorValues) {
    return <div>Loading indicator values...</div>;
  }

  //console.log("Indicator Values:", indicatorValues);

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8 grid grid-cols-2 md:grid-cols-3 gap-4">
      <div>
        <h3 className="text-sm font-semibold text-gray-500">RSI(14)</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.rsi), 2)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MACD</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.macd?.macdLine))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Signal</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.macd?.signalLine))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Histogram</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.macd?.histogram))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">DI+</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.dmi?.diPlus), 2)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">DI-</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.dmi?.diMinus), 2)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">ADX</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.dmi?.adx), 2)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">VWAP</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.vwap))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA5</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma5))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA10</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma10))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA20</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma20))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA50</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma50))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA100</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma100))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">MA200</h3>
        <p className="text-lg font-bold">{formatNumber(getLatestValue(indicatorValues.ma?.ma200))}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">TD Sequential</h3>
        <p className="text-lg font-bold">
          {indicatorValues.tdSequential && indicatorValues.tdSequential.length > 0
            ? indicatorValues.tdSequential[indicatorValues.tdSequential.length - 1].value
            : 'N/A'}
        </p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Stochastic K</h3>
        <p className="text-lg font-bold">{getLatestStochasticValue('k')}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Stochastic D</h3>
        <p className="text-lg font-bold">{getLatestStochasticValue('d')}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">OBV</h3>
        <p className="text-lg font-bold">
          {formatNumber(getLatestValue(indicatorValues.obv), 0)}
        </p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Ichimoku Cloud (Conversion Line)</h3>
        <p className="text-lg font-bold">
          {indicatorValues.ichimoku && indicatorValues.ichimoku.length > 0
            ? formatNumber(indicatorValues.ichimoku[indicatorValues.ichimoku.length - 1].conversionLine, szDecimals)
            : 'N/A'}
        </p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">ATR</h3>
        <p className="text-lg font-bold">
          {formatNumber(getLatestValue(indicatorValues.atr), szDecimals)}
        </p>
      </div>
    </div>
  );
};

export default IndicatorValues;