import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateDMI } from '../utils/indicators';

const DmiChart = ({ data }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 200,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const dmiData = calculateDMI(data);

    const diPlusSeries = chart.addLineSeries({ color: 'green', lineWidth: 2 });
    const diMinusSeries = chart.addLineSeries({ color: 'red', lineWidth: 2 });
    const adxSeries = chart.addLineSeries({ color: 'blue', lineWidth: 2 });

    diPlusSeries.setData(dmiData.diPlus);
    diMinusSeries.setData(dmiData.diMinus);
    adxSeries.setData(dmiData.adx);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data]);

  return <div ref={chartContainerRef} />;
};

export default DmiChart;