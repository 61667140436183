import React, { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Link, useLocation } from 'react-router-dom';

const HelpPages = () => {
  const [categories] = useState({
    Dashboard: [
      {
        id: 1,
        title: 'What is the Dashboard?',
        description: 'The Dashboard provides an overview of your account or selected vault. It displays open positions, account summaries, and real-time data.',
      },
      {
        id: 2,
        title: 'How to use the Dashboard',
        description: 'Navigate through different tabs to view Liquidity Vault, A Vault, B Vault, or Consensus Vault data. Use filters to search for specific coins or assets.',
      },
    ],
    'Perps Data': [
      {
        id: 1,
        title: 'What is Perps Data?',
        description: 'The Perps Data page shows information about perpetual contracts, including mark price, funding rates, open interest, and more.',
      },
      {
        id: 2,
        title: 'How to use Perps Data',
        description: 'Use the search function to filter assets, and click on column headers to sort the data. Click on an asset name to view more detailed information.',
      },
    ],
    'Open Orders': [
      {
        id: 1,
        title: 'What are Open Orders?',
        description: 'The Open Orders page displays all your current active orders that have not been filled or cancelled.',
      },
      {
        id: 2,
        title: 'How to manage Open Orders',
        description: 'You can view both buy and sell orders, filter by asset, and see details such as price, size, and order type.',
      },
    ],
    'Trade History': [
      {
        id: 1,
        title: 'What is Trade History?',
        description: 'The Trade History page shows a record of all your past trades, including both completed and cancelled orders.',
      },
      {
        id: 2,
        title: 'How to analyze Trade History',
        description: 'Use the pagination to navigate through your trade history. Each entry shows details like asset, side, size, price, and execution time.',
      },
    ],
    'Live Market': [
      {
        id: 1,
        title: 'What is the Live Market?',
        description: 'The Live Market page provides real-time data on all available assets, including current prices, 24h changes, and trading volumes.',
      },
      {
        id: 2,
        title: 'How to use Live Market data',
        description: 'Filter assets using the search function, sort by different columns, and click on asset names to view more detailed charts and analysis.',
      },
    ],
    'Market Trends': [
      {
        id: 1,
        title: 'What are Market Trends?',
        description: 'The Market Trends page offers an overview of broader market movements, including top gainers, losers, and trending assets.',
      },
      {
        id: 2,
        title: 'How to interpret Market Trends',
        description: 'Analyze different timeframes (1h, 24h, 7d) to understand short and long-term trends. Use this information to inform your trading decisions.',
      },
    ],
    'Coin Details': [
      {
        id: 1,
        title: 'What are Coin Details?',
        description: 'The Coin Details page provides in-depth information about a specific asset, including price charts, order book, recent trades, and technical indicators.',
      },
      {
        id: 2,
        title: 'How to use Coin Details',
        description: 'Navigate through different tabs to view Overview, Charts, Indicators, and Analysis. Use the timeframe selector to adjust the data range for charts and indicators.',
      },
    ],
  });

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">Pages Help</h2>
      <Link to="/help" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Help Center</Link>
      <div className="w-full max-w-3xl mx-auto px-2 sm:px-0">
        {Object.entries(categories).map(([category, posts]) => (
          <Disclosure as="div" key={category} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>{category}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <ul>
                    {posts.map((post) => (
                      <li
                        key={post.id}
                        className="mb-4"
                      >
                        <h3 className="text-sm font-medium text-gray-900 mb-1">
                          {post.title}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {post.description}
                        </p>
                      </li>
                    ))}
                  </ul>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default HelpPages;
