// src/components/OBVChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const OBVChart = ({ data, obvData }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0 || !obvData) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const obvSeries = chart.addLineSeries({ color: 'purple', lineWidth: 2 });
    obvSeries.setData(obvData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, obvData]);

  return <div ref={chartContainerRef} />;
};

export default OBVChart;