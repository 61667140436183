import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateTDSequential } from '../utils/indicators';

const TDSequentialChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const tdSequential = calculateTDSequential(data);
    
    const markers = tdSequential
      .filter(td => td.value)
      .map(td => ({
        time: td.time,
        position: 'aboveBar',
        color: td.setup === 'bullish' ? 'green' : 'red',
        shape: 'circle',
        text: td.value.toString(),
      }));

    candleSeries.setMarkers(markers);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  const getSignal = () => {
    const lastTD = calculateTDSequential(data).slice(-1)[0];
    if (lastTD.value === 8) {
      return "Potential bounce";
    } else if (lastTD.value === 9) {
      if (data[data.length - 1].close > data[data.length - 1].open) {
        return "Buy 9 spotted. Long";
      } else {
        return "Sell 9 spotted. Short";
      }
    }
    return "";
  };

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-2 font-bold">{getSignal()}</p>
    </div>
  );
};

export default TDSequentialChart;