import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const HeikinAshiChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateHeikinAshi = (data) => {
      const heikinAshi = [];
      for (let i = 0; i < data.length; i++) {
        const ha = {
          time: data[i].time,
          open: i === 0 ? data[i].open : (heikinAshi[i - 1].open + heikinAshi[i - 1].close) / 2,
          high: Math.max(data[i].high, data[i].open, data[i].close),
          low: Math.min(data[i].low, data[i].open, data[i].close),
          close: (data[i].open + data[i].high + data[i].low + data[i].close) / 4
        };
        heikinAshi.push(ha);
      }
      return heikinAshi;
    };

    const heikinAshiData = calculateHeikinAshi(data);

    const heikinAshiSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });
    heikinAshiSeries.setData(heikinAshiData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        Heikin Ashi candlesticks are a variation of traditional Japanese candlesticks. The term "Heikin Ashi" means "average bar" in Japanese. These candlesticks use a modified formula to calculate open, high, low, and close values, which results in a smoother trend and makes it easier to identify trending periods. Heikin Ashi candles are particularly useful for trend identification and reversal detection. In an uptrend, Heikin Ashi candles tend to be green with small or no lower shadows. In a downtrend, they tend to be red with small or no upper shadows. When the trend is changing, the candles often have long lower and upper shadows. Traders use Heikin Ashi charts to more easily spot trends, potential reversals, and to filter out market noise.
      </p>
    </div>
  );
};

export default HeikinAshiChart;