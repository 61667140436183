import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const PivotPointsChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const calculatePivotPoints = (high, low, close) => {
      const pivot = (high + low + close) / 3;
      const r1 = 2 * pivot - low;
      const s1 = 2 * pivot - high;
      const r2 = pivot + (high - low);
      const s2 = pivot - (high - low);
      const r3 = high + 2 * (pivot - low);
      const s3 = low - 2 * (high - pivot);
      return { pivot, r1, s1, r2, s2, r3, s3 };
    };

    const lastCandle = data[data.length - 1];
    const pivotPoints = calculatePivotPoints(lastCandle.high, lastCandle.low, lastCandle.close);

    const addPivotLine = (value, color, label) => {
      const pivotLine = chart.addLineSeries({
        color: color,
        lineWidth: 1,
        lineStyle: 2,
        title: label,
      });
      pivotLine.setData(data.map(d => ({ time: d.time, value: value })));
    };

    addPivotLine(pivotPoints.pivot, 'black', 'Pivot');
    addPivotLine(pivotPoints.r1, 'green', 'R1');
    addPivotLine(pivotPoints.s1, 'red', 'S1');
    addPivotLine(pivotPoints.r2, 'blue', 'R2');
    addPivotLine(pivotPoints.s2, 'orange', 'S2');
    addPivotLine(pivotPoints.r3, 'purple', 'R3');
    addPivotLine(pivotPoints.s3, 'pink', 'S3');

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        Pivot Points are used to determine potential support and resistance levels. They are calculated using the previous period's high, low, and close prices. Traders use these levels to identify possible entry and exit points, as well as potential breakout or breakdown levels. The chart shows the Pivot Point (P) along with three resistance (R1, R2, R3) and three support (S1, S2, S3) levels.
      </p>
    </div>
  );
};

export default PivotPointsChart;