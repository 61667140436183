// src/components/StochasticChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const StochasticChart = ({ data, stochasticData }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0 || !stochasticData) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const kSeries = chart.addLineSeries({ color: 'blue', lineWidth: 2 });
    const dSeries = chart.addLineSeries({ color: 'red', lineWidth: 2 });

    kSeries.setData(stochasticData.map(d => ({ time: d.time, value: d.k })));
    dSeries.setData(stochasticData.map(d => ({ time: d.time, value: d.d })));

    // Add horizontal lines for overbought and oversold levels
    const overboughtLine = chart.addLineSeries({ color: 'rgba(255, 0, 0, 0.5)', lineWidth: 1, lineStyle: 2 });
    const oversoldLine = chart.addLineSeries({ color: 'rgba(0, 255, 0, 0.5)', lineWidth: 1, lineStyle: 2 });

    overboughtLine.setData(stochasticData.map(d => ({ time: d.time, value: 80 })));
    oversoldLine.setData(stochasticData.map(d => ({ time: d.time, value: 20 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, stochasticData]);

  return <div ref={chartContainerRef} />;
};

export default StochasticChart;