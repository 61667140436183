import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateChannelPattern } from '../utils/indicators';

const ChannelPatternChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const { upperChannel, lowerChannel } = calculateChannelPattern(data);

    const upperLineSeries = chart.addLineSeries({
      color: 'rgba(255, 82, 82, 1)', // Red
      lineWidth: 2,
      lineStyle: 2, // Dashed line
    });

    const lowerLineSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)', // Green
      lineWidth: 2,
      lineStyle: 2, // Dashed line
    });

    upperLineSeries.setData(upperChannel);
    lowerLineSeries.setData(lowerChannel);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, timeframe, szDecimals, isSpot, actualDecimals]);

  return <div ref={chartContainerRef} />;
};

export default ChannelPatternChart;