import React from 'react';

const TimeframeSelector = ({ timeframe, onTimeframeChange }) => {
  const timeframes = ['1m', '5m', '15m', '30m', '1h', '4h', '1d'];

  return (
    <div className="mb-4">
      <label htmlFor="timeframe-select" className="block text-sm font-medium text-gray-700 mb-2">
        Select Timeframe:
      </label>
      <select
        id="timeframe-select"
        value={timeframe}
        onChange={(e) => onTimeframeChange(e.target.value)}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {timeframes.map((tf) => (
          <option key={tf} value={tf}>
            {tf}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimeframeSelector;