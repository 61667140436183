import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const MoneyFlowIndexChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateMFI = (data, period = 14) => {
      const mfiData = [];
      for (let i = 1; i < data.length; i++) {
        const typicalPrice = (data[i].high + data[i].low + data[i].close) / 3;
        const rawMoneyFlow = typicalPrice * data[i].volume;
        const moneyFlowRatio = typicalPrice > data[i - 1].typicalPrice ? [rawMoneyFlow, 0] : [0, rawMoneyFlow];
        mfiData.push({ typicalPrice, rawMoneyFlow, moneyFlowRatio });
      }

      const mfi = [];
      for (let i = period; i < mfiData.length; i++) {
        const periodSlice = mfiData.slice(i - period, i);
        const positiveFlow = periodSlice.reduce((sum, d) => sum + d.moneyFlowRatio[0], 0);
        const negativeFlow = periodSlice.reduce((sum, d) => sum + d.moneyFlowRatio[1], 0);
        const mfiValue = 100 - (100 / (1 + positiveFlow / negativeFlow));
        mfi.push({ time: data[i].time, value: mfiValue });
      }

      return mfi;
    };

    const mfiData = calculateMFI(data);

    const mfiSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)',
      lineWidth: 2,
      priceScaleId: '',
      scaleMargins: {
        top: 0.1,
        bottom: 0.1,
      },
    });
    mfiSeries.setData(mfiData);

    // Add overbought and oversold lines
    const overboughtLine = chart.addLineSeries({
      color: 'rgba(255, 0, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });
    const oversoldLine = chart.addLineSeries({
      color: 'rgba(0, 255, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });

    overboughtLine.setData(mfiData.map(d => ({ time: d.time, value: 80 })));
    oversoldLine.setData(mfiData.map(d => ({ time: d.time, value: 20 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        The Money Flow Index (MFI) is a momentum indicator that measures the strength of money flowing in and out of a security. It combines price and volume data to give an idea of buying and selling pressure. The MFI is calculated on a scale of 0 to 100. Values above 80 are considered overbought, while values below 20 are considered oversold. Traders use the MFI to identify potential reversals, overbought/oversold conditions, and divergences between the indicator and price. A rising MFI indicates increasing buying pressure, while a falling MFI suggests increasing selling pressure. Divergences between the MFI and price can signal potential trend reversals.
      </p>
    </div>
  );
};

export default MoneyFlowIndexChart;