import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AISentiment = ({ sentiment, timeframe }) => {
  const priceForecastData = [
    { name: 'Current', price: sentiment.currentPrice },
    { name: '1 Day', price: sentiment.priceForecast.day1, accuracy: sentiment.priceForecast.day1Accuracy },
    { name: '2 Days', price: sentiment.priceForecast.day2, accuracy: sentiment.priceForecast.day2Accuracy },
    { name: '5 Days', price: sentiment.priceForecast.day5, accuracy: sentiment.priceForecast.day5Accuracy },
  ];

  const getTDSequentialText = (tdSequential) => {
    if (!tdSequential || tdSequential.value === null) {
      return "No clear signal";
    }
    return `${tdSequential.setup || ''} ${tdSequential.value || ''}`.trim() || "No clear signal";
  };

  const renderMarketSentimentIndicator = (key, value) => {
    if (key === 'TDSequential') {
      return getTDSequentialText(value);
    }
    return value;
  };

  const formatNumber = (num) => {
    return typeof num === 'number' ? num.toFixed(2) : 'N/A';
  };

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8">
      <h3 className="text-xl font-semibold mb-4">Market Sentiment Analysis ({timeframe})</h3>
      
      <div className="mt-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {Object.entries(sentiment.marketSentiment).map(([key, value]) => (
            <div key={key} className="bg-gray-100 p-2 rounded">
              <p className="font-semibold">{key}</p>
              <p>{renderMarketSentimentIndicator(key, value)}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <h4 className="font-semibold mb-2">Additional Insights</h4>
        {sentiment.additionalInsights.split('. ').map((insight, index) => (
          <p key={index} className="mb-2">{insight}.</p>
        ))}
      </div>
    </div>
  );
};

const getActionColor = (action) => {
  switch (action) {
    case 'BUY':
      return '#4CAF50';
    case 'SELL':
      return '#F44336';
    default:
      return '#FFA000';
  }
};

export default AISentiment;