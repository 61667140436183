import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TradeHistory = ({ wallet }) => {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  // Add this function to truncate the wallet address
  const truncateAddress = (address) => {
    if (address.length <= 10) return address;
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  useEffect(() => {
    if (!wallet) {
      navigate('/');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.post('https://api.hyperliquid.xyz/info', {
          type: 'userFills',
          user: wallet
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [wallet, navigate]);

  if (!wallet) return null;
  if (!data) return <div>Loading...</div>;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const formatTradeSide = (side) => {
    return side === 'B' ? 'BUY/LONG' : 'SELL/SHORT';
  };

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">Trade History for  <span className="hidden md:inline">{wallet}</span> <span className="md:hidden">{truncateAddress(wallet)}</span></h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2">Asset</th>
            <th className="px-4 py-2">Side</th>
            <th className="px-4 py-2">Size</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Time</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((trade, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{trade.coin}</td>
              <td className={`border px-4 py-2 ${trade.side === 'B' ? 'text-green-600' : 'text-red-600'}`}>
                {formatTradeSide(trade.side)}
              </td>
              <td className="border px-4 py-2">{trade.sz}</td>
              <td className="border px-4 py-2">{trade.px}</td>
              <td className="border px-4 py-2">{new Date(trade.time).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex justify-center">
        {[...Array(Math.ceil(data.length / itemsPerPage))].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`mx-1 px-3 py-1 border rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TradeHistory;