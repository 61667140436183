import React from 'react';
import { Link } from 'react-router-dom';

const Help = () => {
  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-4">Help Center</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <Link to="/help/pages" className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <h3 className="text-xl font-semibold mb-2">Pages Help</h3>
          <p>Learn about all the pages and tabs in our application.</p>
        </Link>
        <Link to="/help/charts" className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <h3 className="text-xl font-semibold mb-2">Charts Help</h3>
          <p>Understand how to use and interpret all the charts in the Coin Details page.</p>
        </Link>
        <Link to="/help/indicators" className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <h3 className="text-xl font-semibold mb-2">Indicators Help</h3>
          <p>Learn about all the technical indicators used in our application.</p>
        </Link>
        <Link to="/help/analysis" className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
          <h3 className="text-xl font-semibold mb-2">Analysis Help</h3>
          <p>Understand the different analysis tools and features available.</p>
        </Link>
      </div>
    </div>
  );
}

export default Help;
