import React from 'react';
import { formatTradeSide } from '../utils/indicators';

const RecentTrades = ({ trades, marketTrend, marketTrendColor }) => {
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-xl font-semibold">Recent Trades</h3>
        <span className={`font-semibold ${marketTrendColor}`}>
          Market Trend: {marketTrend}
        </span>
      </div>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Size</th>
            <th className="px-4 py-2">Side</th>
            <th className="px-4 py-2">Time</th>
          </tr>
        </thead>
        <tbody>
          {trades.map((trade, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{trade.px}</td>
              <td className="border px-4 py-2">{trade.sz}</td>
              <td className={`border px-4 py-2 ${trade.side === 'B' ? 'text-green-600' : 'text-red-600'}`}>
                {formatTradeSide(trade.side)}
              </td>
              <td className="border px-4 py-2">{new Date(trade.time).toLocaleTimeString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecentTrades;