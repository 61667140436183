import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateRSI } from '../utils/indicators';

const RsiChart = ({ data, szDecimals, isSpot }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 200,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const rsiData = calculateRSI(data, szDecimals, isSpot);

    const rsiSeries = chart.addLineSeries({ color: 'purple', lineWidth: 2 });
    rsiSeries.setData(rsiData);

    // Add horizontal lines for overbought and oversold levels
    const overboughtLine = chart.addLineSeries({ color: 'red', lineWidth: 1, lineStyle: 2 });
    const oversoldLine = chart.addLineSeries({ color: 'green', lineWidth: 1, lineStyle: 2 });

    overboughtLine.setData(rsiData.map(d => ({ time: d.time, value: 70 })));
    oversoldLine.setData(rsiData.map(d => ({ time: d.time, value: 30 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot]);

  return <div ref={chartContainerRef} />;
};

export default RsiChart;