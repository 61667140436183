import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const WilliamsRChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateWilliamsR = (data, period = 14) => {
      return data.map((_, i, arr) => {
        if (i < period - 1) return { time: arr[i].time, value: null };
        const slice = arr.slice(i - period + 1, i + 1);
        const highestHigh = Math.max(...slice.map(d => d.high));
        const lowestLow = Math.min(...slice.map(d => d.low));
        const currentClose = arr[i].close;
        const r = ((highestHigh - currentClose) / (highestHigh - lowestLow)) * -100;
        return { time: arr[i].time, value: r };
      }).filter(d => d.value !== null);
    };

    const williamsRData = calculateWilliamsR(data);

    const williamsRSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)',
      lineWidth: 2,
      priceScaleId: '',
      scaleMargins: {
        top: 0.1,
        bottom: 0.1,
      },
    });
    williamsRSeries.setData(williamsRData);

    // Add overbought and oversold lines
    const overboughtLine = chart.addLineSeries({
      color: 'rgba(255, 0, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });
    const oversoldLine = chart.addLineSeries({
      color: 'rgba(0, 255, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });

    overboughtLine.setData(williamsRData.map(d => ({ time: d.time, value: -20 })));
    oversoldLine.setData(williamsRData.map(d => ({ time: d.time, value: -80 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        Williams %R, also known as the Williams Percent Range, is a momentum indicator that moves between 0 and -100 and measures overbought and oversold levels. The indicator is very similar to the Stochastic oscillator and is used to identify overbought and oversold conditions. Values between -20 and 0 indicate overbought market conditions, while values between -80 and -100 indicate oversold market conditions. Traders use Williams %R to identify potential entry and exit points, with the idea that overbought conditions may lead to a price decline and oversold conditions may lead to a price increase. However, during strong trends, the indicator may remain in overbought or oversold territory for extended periods. Therefore, it's often used in conjunction with other technical indicators to confirm potential trend reversals.
      </p>
    </div>
  );
};

export default WilliamsRChart;