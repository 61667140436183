import React, { useEffect, useRef, useState } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';

const VwapMaChart = ({ data, vwap, ma5, ma10, ma20, ma50, ma100, ma200, onHover, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    if (!data || data.length === 0) return;

    const chartContainer = chartContainerRef.current;
    const tooltip = tooltipRef.current;

    const chart = createChart(chartContainer, {
      width: chartContainer.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });
    candleSeries.setData(data);

    const addLineSeries = (data, color, lineWidth = 1, lineStyle = 0) => {
      if (data && data.length > 0) {
        const series = chart.addLineSeries({ color, lineWidth, lineStyle });
        series.setData(data);
        return series;
      }
      return null;
    };

    const vwapSeries = addLineSeries(vwap, 'rgba(255, 0, 0, 1)', 2);     // VWAP: Red
    const ma5Series = addLineSeries(ma5, 'rgba(0, 255, 0, 1)');         // MA5: Green
    const ma10Series = addLineSeries(ma10, 'rgba(0, 0, 255, 1)');        // MA10: Blue
    const ma20Series = addLineSeries(ma20, 'rgba(255, 255, 0, 1)');      // MA20: Yellow
    const ma50Series = addLineSeries(ma50, 'rgba(128, 0, 128, 1)');      // MA50: Purple
    const ma100Series = addLineSeries(ma100, 'rgba(0, 255, 255, 1)');     // MA100: Cyan
    const ma200Series = addLineSeries(ma200, 'rgba(128, 128, 128, 1)');   // MA200: Gray

    const findClosestMA200Value = (time) => {
      if (!ma200 || ma200.length === 0) return null;
      let closest = ma200[0];
      let minDiff = Math.abs(time - closest.time);
      for (let i = 1; i < ma200.length; i++) {
        const diff = Math.abs(time - ma200[i].time);
        if (diff < minDiff) {
          minDiff = diff;
          closest = ma200[i];
        }
      }
      return closest.value;
    };

    chart.subscribeCrosshairMove((param) => {
      if (
        !param ||
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainer.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainer.clientHeight
      ) {
        tooltip.style.display = 'none';
        onHover(null);
      } else {
        const dateStr = new Date(param.time * 1000).toLocaleDateString();
        const seriesData = param.seriesData;
        
        const getValue = (series) => {
          if (series && seriesData.has(series)) {
            const value = seriesData.get(series);
            if (typeof value === 'number') {
              return value.toFixed(Math.max(szDecimals, actualDecimals));
            } else if (value && typeof value.value === 'number') {
              return value.value.toFixed(Math.max(szDecimals, actualDecimals));
            } else if (value && typeof value.close === 'number') {
              return value.close.toFixed(Math.max(szDecimals, actualDecimals));
            }
          }
          return 'N/A';
        };

        const price = getValue(candleSeries);
        const vwapValue = getValue(vwapSeries);
        const ma5Value = getValue(ma5Series);
        const ma10Value = getValue(ma10Series);
        const ma20Value = getValue(ma20Series);
        const ma50Value = getValue(ma50Series);
        const ma100Value = getValue(ma100Series);
        const ma200Value = findClosestMA200Value(param.time);

        const values = {
          price,
          vwap: vwapValue,
          ma5: ma5Value,
          ma10: ma10Value,
          ma20: ma20Value,
          ma50: ma50Value,
          ma100: ma100Value,
          ma200: ma200Value ? ma200Value.toFixed(Math.max(szDecimals, actualDecimals)) : 'N/A'
        };

        onHover(values);

        tooltip.style.display = 'block';
        tooltip.style.left = param.point.x + 'px';
        tooltip.style.top = param.point.y + 'px';
        tooltip.innerHTML = `
          <div>Date: ${dateStr}</div>
          <div>Price: ${price}</div>
          <div style="color: red;">VWAP: ${vwapValue}</div>
          <div style="color: green;">MA5: ${ma5Value}</div>
          <div style="color: blue;">MA10: ${ma10Value}</div>
          <div style="color: yellow;">MA20: ${ma20Value}</div>
          <div style="color: purple;">MA50: ${ma50Value}</div>
          <div style="color: cyan;">MA100: ${ma100Value}</div>
          <div style="color: gray;">MA200: ${values.ma200}</div>
        `;
      }
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainer.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, vwap, ma5, ma10, ma20, ma50, ma100, ma200, onHover, szDecimals, isSpot, actualDecimals]);

  return (
    <div style={{ position: 'relative' }}>
      <div ref={chartContainerRef} />
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          display: 'none',
          padding: '8px',
          boxSizing: 'border-box',
          fontSize: '12px',
          color: '#333',
          background: 'rgba(255, 255, 255, 0.8)',
          border: '1px solid #2962FF',
          borderRadius: '2px',
          pointerEvents: 'none',
          zIndex: 1000,
        }}
      />
    </div>
  );
};

export default VwapMaChart;