// src/components/IchimokuCloudChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const IchimokuCloudChart = ({ data, ichimokuData }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0 || !ichimokuData) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const conversionLine = chart.addLineSeries({ color: 'blue', lineWidth: 1 });
    const baseLine = chart.addLineSeries({ color: 'red', lineWidth: 1 });
    const leadingSpanA = chart.addLineSeries({ color: 'green', lineWidth: 1 });
    const leadingSpanB = chart.addLineSeries({ color: 'orange', lineWidth: 1 });
    const laggingSpan = chart.addLineSeries({ color: 'purple', lineWidth: 1 });

    conversionLine.setData(ichimokuData.map(d => ({ time: d.time, value: d.conversionLine })));
    baseLine.setData(ichimokuData.map(d => ({ time: d.time, value: d.baseLine })));
    leadingSpanA.setData(ichimokuData.map(d => ({ time: d.time, value: d.leadingSpanA })));
    leadingSpanB.setData(ichimokuData.map(d => ({ time: d.time, value: d.leadingSpanB })));
    laggingSpan.setData(ichimokuData.map(d => ({ time: d.time, value: d.laggingSpan })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, ichimokuData]);

  return <div ref={chartContainerRef} />;
};

export default IchimokuCloudChart;