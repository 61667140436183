import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateBollingerBands } from '../utils/indicators';

const BollingerBandsChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const bollingerBands = calculateBollingerBands(data, 20, 2, szDecimals, isSpot, actualDecimals);

    const upperBandSeries = chart.addLineSeries({ color: 'rgba(76, 175, 80, 0.5)', lineWidth: 1 });
    const middleBandSeries = chart.addLineSeries({ color: 'rgba(33, 150, 243, 0.5)', lineWidth: 1 });
    const lowerBandSeries = chart.addLineSeries({ color: 'rgba(76, 175, 80, 0.5)', lineWidth: 1 });

    upperBandSeries.setData(bollingerBands.map(d => ({ time: d.time, value: d.upper })));
    middleBandSeries.setData(bollingerBands.map(d => ({ time: d.time, value: d.middle })));
    lowerBandSeries.setData(bollingerBands.map(d => ({ time: d.time, value: d.lower })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return <div ref={chartContainerRef} />;
};

export default BollingerBandsChart;