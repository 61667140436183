import React from 'react';

const CoinStatsSummary = ({ coinStats }) => {
  if (!coinStats) return null;
  
  const formatNumber = (num, szDecimals) => {
    const maxDecimals = coinStats.isSpot ? 8 : 6;
    const allowedDecimals = Math.min(maxDecimals - szDecimals, 5);
    return Number(num).toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: allowedDecimals,
    });
  };

  const formatPrice = (price) => {
    return formatNumber(price, coinStats.szDecimals);
  };

  const formatPercentage = (num) => {
    return num.toFixed(2) + '%';
  };

  if (!coinStats) return null;

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8 grid grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Price</h3>
        <p className="text-lg font-bold">${formatPrice(coinStats.markPx)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">24h Change</h3>
        <p className={`text-lg font-bold ${coinStats.percentChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
          {formatPercentage(coinStats.percentChange)}
        </p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">24h Volume</h3>
        <p className="text-lg font-bold">${formatNumber(coinStats.dayNtlVlm, 0)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Yesterday's Price</h3>
        <p className="text-lg font-bold">${formatPrice(coinStats.prevDayPx)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Open Interest</h3>
        <p className="text-lg font-bold">{formatNumber(coinStats.openInterest, coinStats.szDecimals)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Funding Rate</h3>
        <p className="text-lg font-bold">{formatNumber(coinStats.funding, 0)}</p>
      </div>
      <div>
        <h3 className="text-sm font-semibold text-gray-500">Max Leverage</h3>
        <p className="text-lg font-bold">{coinStats.maxLeverage}x</p>
      </div>
    </div>
  );
};

export default CoinStatsSummary;