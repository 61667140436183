import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const FibonacciLevelsChart = ({ data, fibonacciLevels, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0 || !fibonacciLevels || fibonacciLevels.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
    });

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    candleSeries.setData(data);

    const colors = ['#FFA500', '#FF4500', '#FF6347', '#FF7F50', '#FF8C00', '#FFA07A'];

    fibonacciLevels.forEach((level, index) => {
      const lineSeries = chart.addLineSeries({
        color: colors[index % colors.length],
        lineWidth: 1,
        lineStyle: 2,
        title: `Fib ${level.level.toFixed(3)}`,
      });

      lineSeries.setData(data.map(d => ({ time: d.time, value: level.value })));
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, fibonacciLevels, szDecimals, isSpot, actualDecimals]);

  return <div ref={chartContainerRef} />;
};

export default FibonacciLevelsChart;