// src/pages/MarketTrends.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import axios from 'axios';

const MarketTrends = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countdown, setCountdown] = useState(60);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get('https://api.crypn.dev/cb.php');
      setData(response.data);
      setLoading(false);
      setCountdown(60);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    const fetchInterval = setInterval(fetchData, 60000); // Fetch every minute

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : 60));
    }, 1000);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(fetchInterval);
      clearInterval(countdownInterval);
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchData]);

  const formatPercentage = (value) => {
    const numValue = Number(value);
    const color = numValue >= 0 ? 'text-green-600' : 'text-red-600';
    return <span className={color}>{numValue.toFixed(2)}%</span>;
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank',
      },
      {
        Header: 'Token',
        accessor: 'symbol',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => {
          // Convert to number if it's a string
          const price = typeof value === 'string' ? parseFloat(value) : value;
          
          if (price < 0.000000001) {
            // For extremely small prices, use scientific notation
            return price.toExponential(4);
          } else if (price < 0.01) {
            // For small prices, show up to 9 decimal places
            return '$' + price.toFixed(9).replace(/\.?0+$/, '');
          } else {
            // For larger prices, use standard formatting
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(price);
          }
        },
      },
      {
        Header: 'Dominance',
        accessor: 'dominance',
        Cell: ({ value }) => {
          const dominancePercentage = (value * 100).toFixed(2);
          return `${dominancePercentage}%`;
        },
      },
      {
        Header: 'Market Cap',
        accessor: 'marketcap',
        Cell: ({ value }) => `$${Number(value).toLocaleString()}`,
      },
      {
        Header: 'Volume',
        accessor: 'volume',
        Cell: ({ value }) => `$${Number(value).toLocaleString()}`,
      },
      {
        Header: '1 Min',
        accessor: 'performance.min1',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: '5 Mins',
        accessor: 'performance.min5',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: '15 Mins',
        accessor: 'performance.min15',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: 'Hour',
        accessor: 'performance.hour',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: 'Day',
        accessor: 'performance.day',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: 'Week',
        accessor: 'performance.week',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: 'Month',
        accessor: 'performance.month',
        Cell: ({ value }) => formatPercentage(value),
      },
      {
        Header: 'Year',
        accessor: 'performance.year',
        Cell: ({ value }) => formatPercentage(value),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: isMobile ? 5 : 20 },
      autoResetPage: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(isMobile ? 5 : 20);
  }, [isMobile, setPageSize]);

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto mt-16 p-4">
      <h1 className="text-2xl font-bold mb-4">Market Trends</h1>
      <div className="mb-4 flex flex-col md:flex-row md:justify-between md:items-center">
        <input
          value={globalFilter || ''}
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Filter by token or name"
          className="px-3 py-2 border rounded mb-2 md:mb-0"
        />
        <p className="md:mb-0">This page refreshes every minute: <span className="font-bold">{countdown}</span> seconds</p>
      </div>
      <div className="overflow-x-auto">
        <div className="hidden md:block"> {/* Table for larger screens */}
          <table {...getTableProps()} className="min-w-full bg-white">
            <thead>
              {headerGroups.map(headerGroup => {
                const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                return (
                  <tr key={headerGroupKey} {...headerGroupProps}>
                    {headerGroup.headers.map(column => {
                      const { key: headerKey, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th
                          key={headerKey}
                          {...headerProps}
                          className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <tr key={rowKey} {...rowProps}>
                    {row.cells.map(cell => {
                      const { key: cellKey, ...cellProps } = cell.getCellProps();
                      return (
                        <td key={cellKey} {...cellProps} className="px-4 py-2 whitespace-nowrap">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="md:hidden"> {/* Card layout for mobile */}
          {page.map(row => {
            prepareRow(row);
            return (
              <div key={row.id} className="bg-white shadow rounded-lg mb-4 p-4">
                {row.cells.map(cell => (
                  <div key={cell.column.id} className="mb-2">
                    <span className="font-bold">{cell.column.Header}: </span>
                    {cell.render('Cell')}
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-4 flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center mb-2 sm:mb-0">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="mr-2 px-3 py-1 border rounded bg-white disabled:opacity-50"
          >
            {'<<'}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mr-2 px-3 py-1 border rounded bg-white disabled:opacity-50"
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="mr-2 px-3 py-1 border rounded bg-white disabled:opacity-50"
          >
            {'>'}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="mr-2 px-3 py-1 border rounded bg-white disabled:opacity-50"
          >
            {'>>'}
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">
            Page <strong>{pageIndex + 1}</strong> of <strong>{pageOptions.length}</strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="px-2 py-1 border rounded"
          >
            {[5, 10, 20, 30, 40, 50].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default MarketTrends;