import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const CCIChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateCCI = (data, period = 20) => {
      const cciData = [];
      for (let i = period - 1; i < data.length; i++) {
        const slice = data.slice(i - period + 1, i + 1);
        const tp = slice.map(d => (d.high + d.low + d.close) / 3);
        const sma = tp.reduce((sum, val) => sum + val, 0) / period;
        const mad = tp.reduce((sum, val) => sum + Math.abs(val - sma), 0) / period;
        const cci = (tp[tp.length - 1] - sma) / (0.015 * mad);
        cciData.push({ time: data[i].time, value: cci });
      }
      return cciData;
    };

    const cciData = calculateCCI(data);

    const cciSeries = chart.addLineSeries({
      color: 'rgba(76, 175, 80, 1)',
      lineWidth: 2,
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    cciSeries.setData(cciData);

    // Add overbought and oversold lines
    const overboughtLine = chart.addLineSeries({
      color: 'rgba(255, 0, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });
    const oversoldLine = chart.addLineSeries({
      color: 'rgba(0, 255, 0, 0.5)',
      lineWidth: 1,
      lineStyle: 2,
      priceScaleId: '',
    });

    overboughtLine.setData(cciData.map(d => ({ time: d.time, value: 100 })));
    oversoldLine.setData(cciData.map(d => ({ time: d.time, value: -100 })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        The Commodity Channel Index (CCI) is a versatile indicator used to identify cyclical trends not only in commodities, but also equities and currencies. The CCI measures the difference between the current price and its average price over a given period of time. The indicator oscillates above and below a zero line. Values above +100 imply an overbought condition, while values below -100 imply an oversold condition. Traders use the CCI to identify new trends, overbought/oversold conditions, and potential trend reversals.
      </p>
    </div>
  );
};

export default CCIChart;