import React, { useEffect, useRef, useState, useMemo } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { detectHarmonicPatterns } from '../utils/indicators';

const PATTERN_COLORS = {
  GARTLEY: 'rgba(255, 0, 0, 0.8)',
  BUTTERFLY: 'rgba(0, 255, 0, 0.8)',
  BAT: 'rgba(0, 0, 255, 0.8)',
  CRAB: 'rgba(255, 255, 0, 0.8)',
};

const HarmonicPatternsChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();
  const chartRef = useRef(null);
  const candleSeriesRef = useRef(null);
  const patternSeriesRef = useRef([]);
  const [sensitivity, setSensitivity] = useState(0.49);
  const [visiblePatterns, setVisiblePatterns] = useState(Object.keys(PATTERN_COLORS));
  const [showPatternBreakdown, setShowPatternBreakdown] = useState(false);

  const patterns = useMemo(() => detectHarmonicPatterns(data, sensitivity), [data, sensitivity]);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const handleResize = () => {
      if (chartRef.current) {
        chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
      }
    };

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 400,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: CrosshairMode.Normal },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: { 
        borderColor: 'rgba(197, 203, 206, 1)',
        timeVisible: true,
        secondsVisible: false,
      },
    });

    chartRef.current = chart;

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    candleSeriesRef.current = candleSeries;
    candleSeries.setData(data);

    chart.timeScale().fitContent();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartRef.current) {
        chartRef.current.remove();
        chartRef.current = null;
        candleSeriesRef.current = null;
        patternSeriesRef.current = [];
      }
    };
  }, [data, timeframe, szDecimals, isSpot, actualDecimals]);

  useEffect(() => {
    if (!chartRef.current || !candleSeriesRef.current) return;
  
    // Remove existing pattern series
    patternSeriesRef.current.forEach(series => {
      if (chartRef.current && series) {
        chartRef.current.removeSeries(series);
      }
    });
    patternSeriesRef.current = [];
  
    let allMarkers = [];
  
    // Render patterns
    patterns.forEach((pattern) => {
      if (visiblePatterns.includes(pattern.type)) {
        // Add the larger shape
        const shapeSeries = chartRef.current.addLineSeries({
          color: PATTERN_COLORS[pattern.type],
          lineWidth: 2,
          lineStyle: 2, // Dashed line
          lastPriceAnimation: 0,
        });
        
        const shapeData = [
          { time: pattern.X.time, value: pattern.X.price },
          { time: pattern.A.time, value: pattern.A.price },
          { time: pattern.B.time, value: pattern.B.price },
          { time: pattern.C.time, value: pattern.C.price },
          { time: pattern.D.time, value: pattern.D.price },
        ];
        
        // Remove duplicate timestamps and ensure ascending order
        const uniqueShapeData = shapeData.reduce((acc, curr) => {
          if (!acc.find(item => item.time === curr.time)) {
            acc.push(curr);
          }
          return acc;
        }, []).sort((a, b) => a.time - b.time);
        
        if (uniqueShapeData.length >= 2) {
          shapeSeries.setData(uniqueShapeData);
          patternSeriesRef.current.push(shapeSeries);
  
          // Add marker for pattern
          allMarkers.push({
            time: pattern.D.time,
            position: 'aboveBar',
            color: PATTERN_COLORS[pattern.type],
            shape: 'circle',
            size: 1, // Smaller size
          });
        }
      }
    });
  
    // Sort all markers by time and remove duplicates
    allMarkers = allMarkers.reduce((acc, curr) => {
      if (!acc.find(item => item.time === curr.time)) {
        acc.push(curr);
      }
      return acc;
    }, []).sort((a, b) => a.time - b.time);
  
    // Set all markers at once
    if (candleSeriesRef.current) {
      candleSeriesRef.current.setMarkers(allMarkers);
    }
  
  }, [patterns, visiblePatterns, sensitivity]);

  useEffect(() => {
    if (chartRef.current) {
      const toolTip = document.createElement('div');
      toolTip.style.position = 'absolute';
      toolTip.style.display = 'none';
      toolTip.style.padding = '8px';
      toolTip.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
      toolTip.style.color = 'black';
      toolTip.style.border = '1px solid #ccc';
      toolTip.style.borderRadius = '4px';
      toolTip.style.zIndex = '1000';
      chartContainerRef.current.appendChild(toolTip);

      const crosshairMoveHandler = (param) => {
        if (param.point) {
          const pattern = patterns.find(p => 
            p.X.time <= param.time && p.D.time >= param.time
          );

          if (pattern) {
            const containerRect = chartContainerRef.current.getBoundingClientRect();
            toolTip.style.left = `${param.point.x + 10}px`;
            toolTip.style.top = `${param.point.y + 10}px`;
            toolTip.innerHTML = `
              <div>Pattern: ${pattern.type}</div>
              <div>XAB: ${pattern.XAB.toFixed(3)}</div>
              <div>ABC: ${pattern.ABC.toFixed(3)}</div>
              <div>BCD: ${pattern.BCD.toFixed(3)}</div>
              <div>XAD: ${pattern.XAD.toFixed(3)}</div>
            `;
            toolTip.style.display = 'block';
          } else {
            toolTip.style.display = 'none';
          }
        } else {
          toolTip.style.display = 'none';
        }
      };

      chartRef.current.subscribeCrosshairMove(crosshairMoveHandler);

      return () => {
        if (chartRef.current) {
          chartRef.current.unsubscribeCrosshairMove(crosshairMoveHandler);
        }
        if (chartContainerRef.current) {
          chartContainerRef.current.removeChild(toolTip);
        }
      };
    }
  }, [patterns]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  };

  const renderPatternBreakdown = () => {
    if (!showPatternBreakdown) return null;

    return (
      <div>
        <h3>Patterns Breakdown:</h3>
        {Object.keys(PATTERN_COLORS).map(patternType => {
          const filteredPatterns = patterns.filter(p => p.type === patternType);
          if (filteredPatterns.length === 0) return null;

          return (
            <div key={patternType}>
              <h4>{patternType} Patterns: {filteredPatterns.length}</h4>
              {filteredPatterns.map((pattern, index) => (
                <div key={index}>
                  {formatDate(pattern.D.time)} [XAB: {pattern.XAB.toFixed(3)} | ABC: {pattern.ABC.toFixed(3)} | BCD: {pattern.BCD.toFixed(3)} | XAD: {pattern.XAD.toFixed(3)}]
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <div ref={chartContainerRef} style={{ position: 'relative', height: '400px', width: '100%' }} />
      <div>
        <label>
          Sensitivity:
          <input
            type="range"
            min="0.05"
            max="1"
            step="0.01"
            value={sensitivity}
            onChange={(e) => setSensitivity(parseFloat(e.target.value))}
          />
          {sensitivity.toFixed(2)}
        </label>
      </div>
      <div>
        <h3>Pattern Types:</h3>
        {Object.keys(PATTERN_COLORS).map(type => (
          <label key={type}>
            <input
              type="checkbox"
              checked={visiblePatterns.includes(type)}
              onChange={(e) => {
                if (e.target.checked) {
                  setVisiblePatterns([...visiblePatterns, type]);
                } else {
                  setVisiblePatterns(visiblePatterns.filter(t => t !== type));
                }
              }}
            />
            {type}
            <span style={{ backgroundColor: PATTERN_COLORS[type], padding: '0 5px' }}>&nbsp;</span>
          </label>
        ))}
      </div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={showPatternBreakdown}
            onChange={(e) => setShowPatternBreakdown(e.target.checked)}
          />
          Show Pattern Type Breakdown
        </label>
      </div>
      {renderPatternBreakdown()}
      <div>
        <h3>Legend:</h3>
        <div>GARTLEY: A harmonic pattern that signals a potential reversal, consisting of five points (X, A, B, C, D)</div>
        <div>BUTTERFLY: Similar to Gartley, but with different Fibonacci ratios, often signaling stronger reversals</div>
        <div>BAT: Another five-point reversal pattern with unique Fibonacci ratios</div>
        <div>CRAB: A pattern with extreme projections, often signaling sharp reversals</div>
      </div>
    </div>
  );
};

export default HarmonicPatternsChart;