import React from 'react';
import { Link } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';

const HelpAnalysis = () => {
  const analysisModules = [
    {
      name: 'AI Sentiment Analysis',
      description: 'This module uses artificial intelligence to analyze market sentiment based on various factors.',
      usage: 'Use the AI sentiment analysis to get an overall feel for the market direction. It can help confirm your own analysis or highlight potential trend changes.',
    },
    {
      name: 'Price Forecast',
      description: 'The price forecast module attempts to predict future price movements based on historical data and current market conditions.',
      usage: 'While no prediction is guaranteed, use this forecast as one of many tools to inform your trading decisions. Always combine it with other forms of analysis.',
    },
    {
      name: 'Trading Recommendation',
      description: 'This module provides suggested trading actions based on current market conditions and technical analysis.',
      usage: 'Consider the trading recommendations as suggestions, not absolute instructions. Always do your own research and manage your risk appropriately.',
    },
    {
      name: 'Market Assessment',
      description: 'The market assessment provides an overview of current market conditions, including trend strength and potential support/resistance levels.',
      usage: 'Use this assessment to understand the broader market context. It can help you align your trading strategy with overall market movements.',
    },
    {
      name: 'Technical Analysis Summary',
      description: 'This module summarizes key technical indicators and their current signals.',
      usage: 'Use the technical analysis summary to quickly gauge the overall technical picture. Look for confluence among multiple indicators for stronger signals.',
    },
    {
      name: 'Pattern Recognition',
      description: 'The pattern recognition module identifies common chart patterns that may indicate future price movements.',
      usage: 'When a pattern is identified, look for confirmation from other indicators or price action before making trading decisions based on the pattern.',
    },
    {
      name: 'Consensus Signals',
      description: 'This module combines multiple technical indicators to generate consensus buy or sell signals.',
      usage: 'Use consensus signals as a high-level overview of market sentiment. Strong consensus can indicate potential trend continuations or reversals.',
    },
    {
      name: 'Divergence Analysis',
      description: 'This tool identifies divergences between price action and various technical indicators.',
      usage: 'Divergences can signal potential trend reversals. Look for price making new highs/lows while indicators fail to confirm these new extremes.',
    },
    {
      name: 'Support and Resistance Analysis',
      description: 'This module identifies key support and resistance levels based on historical price action.',
      usage: 'Use these levels to identify potential entry, exit, or stop-loss points. Be aware of price reactions around these levels.',
    },
    {
      name: 'Volatility Analysis',
      description: 'This tool analyzes market volatility using indicators like Average True Range (ATR).',
      usage: 'Use volatility analysis to adjust your position sizing and stop-loss levels. Higher volatility may require wider stops and smaller position sizes.',
    },
  ];

  return (
    <div className="container mx-auto mt-16 p-4">
      <h2 className="text-2xl font-bold mb-2">Analysis Help</h2>
      <Link to="/help" className="text-blue-600 hover:text-blue-800 mb-4 inline-block">&larr; Back to Help Center</Link>
      <div className="w-full max-w-3xl mx-auto px-2 sm:px-0">
        {analysisModules.map((module) => (
          <Disclosure as="div" key={module.name} className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>{module.name}</span>
                  <ChevronUpIcon
                    className={`${
                      open ? 'rotate-180 transform' : ''
                    } h-5 w-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <h3 className="font-medium text-gray-900 mb-2">Description</h3>
                  <p className="mb-4">{module.description}</p>
                  <h3 className="font-medium text-gray-900 mb-2">How to Use</h3>
                  <p>{module.usage}</p>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default HelpAnalysis;