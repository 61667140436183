import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const ParabolicSARChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const calculatePSAR = (data, step = 0.02, max = 0.2) => {
      let psar = data[0].low;
      let ep = data[0].high;
      let af = step;
      let bull = true;
      const psarData = [];

      for (let i = 0; i < data.length; i++) {
        if (bull) {
          psar = psar + af * (ep - psar);
          if (data[i].low < psar) {
            bull = false;
            psar = ep;
            ep = data[i].low;
            af = step;
          } else {
            if (data[i].high > ep) {
              ep = data[i].high;
              af = Math.min(af + step, max);
            }
          }
        } else {
          psar = psar - af * (psar - ep);
          if (data[i].high > psar) {
            bull = true;
            psar = ep;
            ep = data[i].high;
            af = step;
          } else {
            if (data[i].low < ep) {
              ep = data[i].low;
              af = Math.min(af + step, max);
            }
          }
        }
        psarData.push({ time: data[i].time, value: psar });
      }
      return psarData;
    };

    const psarData = calculatePSAR(data);

    const psarSeries = chart.addLineSeries({
      color: 'rgba(255, 0, 0, 1)',
      lineWidth: 1,
      pointsVisible: true,
      pointSize: 3,
      title: 'Parabolic SAR',
    });
    psarSeries.setData(psarData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        The Parabolic SAR (Stop and Reverse) is a popular indicator used to determine the direction of an asset's momentum and the potential reversal of that momentum. It appears as a series of dots placed either above or below an asset's price, depending on the price momentum. When the dots are below the price, it's considered a bullish signal, and when they're above, it's considered bearish. Traders often use this indicator to set trailing stop-loss orders and to identify potential entry and exit points.
      </p>
    </div>
  );
};

export default ParabolicSARChart;