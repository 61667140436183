import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { TabProvider } from './TabContext';

// Import components
import Header from './components/Header';
import WalletInput from './components/WalletInput';
import Dashboard from './pages/Dashboard';
import PerpsData from './pages/PerpsData';
import OpenOrders from './pages/OpenOrders';
import TradeHistory from './pages/TradeHistory';
import Market from './pages/Market';
import CoinDetails from './pages/CoinDetails';
import MarketTrends from './pages/MarketTrends';
import Help from './pages/Help';
import HelpPages from './pages/HelpPages';
import HelpCharts from './pages/HelpCharts';
import HelpIndicators from './pages/HelpIndicators';
import HelpAnalysis from './pages/HelpAnalysis';
import ChartImagePage from './pages/ChartImagePage';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AppContent = () => {
  const [wallet, setWallet] = useState('');
  const query = useQuery();
  const urlWallet = query.get('wallet');

  useEffect(() => {
    if (urlWallet && !wallet) {
      setWallet(urlWallet);
    }
  }, [urlWallet, wallet]);

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <Routes>
        <Route 
          path="/" 
          element={wallet ? <Navigate to="/dashboard" /> : <WalletInput onSubmit={setWallet} />} 
        />
        <Route 
          path="/dashboard" 
          element={<Dashboard wallet={wallet || urlWallet} />} 
        />
        <Route path="/perps-data" element={<PerpsData />} />
        <Route 
          path="/open-orders" 
          element={<OpenOrders wallet={wallet || urlWallet} />} 
        />
        <Route 
          path="/trade-history" 
          element={<TradeHistory wallet={wallet || urlWallet} />} 
        />
        <Route path="/live-market" element={<Market />} />
        <Route path="/market-trends" element={<MarketTrends />} />
        <Route path="/coin/:coin" element={
          <ErrorBoundary>
            <CoinDetails />
          </ErrorBoundary>
        } />
        <Route path="/help" element={<Help />} />
        <Route path="/help/pages" element={<HelpPages />} />
        <Route path="/help/charts" element={<HelpCharts />} />
        <Route path="/help/indicators" element={<HelpIndicators />} />
        <Route path="/help/analysis" element={<HelpAnalysis />} />
        <Route path="/chart-image" element={<ChartImagePage />} />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <TabProvider>
        <AppContent />
      </TabProvider>
    </Router>
  );
};

export default App;