import React from 'react';

const FibonacciLevels = ({ levels, szDecimals, isSpot }) => {
  const formatNumber = (num) => {
    if (typeof num !== 'number') return 'N/A';
    const maxDecimals = isSpot ? 8 : 6;
    const allowedDecimals = Math.min(maxDecimals - (szDecimals || 0), 5);
    return num.toFixed(allowedDecimals);
  };

  if (!levels || levels.length === 0) {
    return <div>No Fibonacci levels available</div>;
  }

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8">
      <h3 className="text-xl font-semibold mb-2">Fibonacci Levels</h3>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {levels.map((level, index) => (
          <div key={index}>
            <h4 className="text-sm font-semibold text-gray-500">{level.level.toFixed(3)}</h4>
            <p className="text-lg font-bold">{formatNumber(level.value)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FibonacciLevels;