import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const KeltnerChannelsChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const candleSeries = chart.addCandlestickSeries();
    candleSeries.setData(data);

    const calculateKeltnerChannels = (data, period = 20, multiplier = 2) => {
      const ema = [];
      const atr = [];
      const keltnerChannels = [];
    
      for (let i = 0; i < data.length; i++) {
        if (i < period) {
          ema.push(null);
          atr.push(null);
          keltnerChannels.push({ time: data[i].time, middle: null, upper: null, lower: null });
          continue;
        }
    
        const slice = data.slice(i - period + 1, i + 1);
        const emaValue = slice.reduce((sum, candle) => sum + candle.close, 0) / period;
        ema.push(emaValue);
    
        const tr = Math.max(
          data[i].high - data[i].low,
          Math.abs(data[i].high - data[i - 1].close),
          Math.abs(data[i].low - data[i - 1].close)
        );
        const atrValue = (atr[i - 1] * (period - 1) + tr) / period;
        atr.push(atrValue);
    
        keltnerChannels.push({
          time: data[i].time,
          middle: emaValue,
          upper: emaValue + multiplier * atrValue,
          lower: emaValue - multiplier * atrValue
        });
      }
    
      return keltnerChannels.filter(d => d.middle !== null);
    };

    const keltnerData = calculateKeltnerChannels(data);

    const middleSeries = chart.addLineSeries({ color: 'rgba(76, 175, 80, 1)', lineWidth: 2 });
    const upperSeries = chart.addLineSeries({ color: 'rgba(255, 0, 0, 1)', lineWidth: 1 });
    const lowerSeries = chart.addLineSeries({ color: 'rgba(0, 0, 255, 1)', lineWidth: 1 });

    middleSeries.setData(keltnerData.map(d => ({ time: d.time, value: d.middle })));
    upperSeries.setData(keltnerData.map(d => ({ time: d.time, value: d.upper })));
    lowerSeries.setData(keltnerData.map(d => ({ time: d.time, value: d.lower })));

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        Keltner Channels are volatility-based envelopes set above and below an exponential moving average. This indicator is similar to Bollinger Bands, which use the standard deviation to set the bands. Instead, Keltner Channels use the Average True Range (ATR) to set channel distance. The channels can be used to identify the trend direction, spot potential reversals, and provide overbought and oversold signals. When the price touches or breaches the upper channel, it may indicate overbought conditions. Conversely, when the price touches or breaches the lower channel, it may indicate oversold conditions. Traders also watch for price action between the upper and lower channels to gauge trend strength and potential breakouts.
      </p>
    </div>
  );
};

export default KeltnerChannelsChart;