import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'lucide-react';
import { useTab } from '../TabContext';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { activeTab, setActiveTab } = useTab();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isCoinDetailsPage = location.pathname.startsWith('/coin/');
  const vaultMode = location.state?.vaultMode;

  return (
    <header className="fixed top-0 left-0 right-0 z-50">
      <div className="bg-gray-800 text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold">Crypn</h1>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <Menu size={24} />
            </button>
          </div>
          <nav className={`md:flex ${isMenuOpen ? 'block' : 'hidden'} absolute md:relative top-full left-0 right-0 bg-gray-800 md:bg-transparent`}>
            <div className="flex flex-col md:flex-row">
              <Link to="/dashboard" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Dashboard</Link>
              <Link to="/perps-data" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Perps Data</Link>
              <Link to="/open-orders" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Open Orders</Link>
              <Link to="/trade-history" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Trade History</Link>
              <Link to="/live-market" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Live Market</Link>
              <Link to="/market-trends" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Market Trends</Link>
              <Link to="/help" className="px-4 py-2 hover:bg-gray-700 md:hover:bg-transparent md:hover:text-gray-300">Help</Link>
            </div>
          </nav>
        </div>
      </div>
      {isCoinDetailsPage && (
        <div className="bg-gray-700 p-2">
          <div className="container mx-auto">
            <ul className="flex justify-center">
              <li className={`mr-6 ${activeTab === 'overview' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('overview')}>Overview</button>
              </li>
              <li className={`mr-6 ${activeTab === 'viewCharts' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('viewCharts')}>Charts</button>
              </li>
              <li className={`mr-6 ${activeTab === 'indicatorData' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('indicatorData')}>Indicators</button>
              </li>
              <li className={`mr-6 ${activeTab === 'aiAnalysis' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('aiAnalysis')}>Analysis</button>
              </li>
            </ul>
          </div>
        </div>
      )}
      {vaultMode && (
        <div className="bg-gray-700 p-2">
          <div className="container mx-auto">
            <ul className="flex justify-center">
              <li className={`mr-6 ${activeTab === 'Liquidity Vault' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('Liquidity Vault')}>Liquidity</button>
              </li>
              <li className={`mr-6 ${activeTab === 'A Vault' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('A Vault')}>A Vault</button>
              </li>
              <li className={`mr-6 ${activeTab === 'B Vault' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('B Vault')}>B Vault</button>
              </li>
              <li className={`mr-6 ${activeTab === 'Consensus Vault' ? 'font-bold text-white' : 'text-gray-300'}`}>
                <button onClick={() => setActiveTab('Consensus Vault')}>Consensus</button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;