import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const ChaikinMoneyFlowChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: 1 },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
    });

    const calculateCMF = (data, period = 20) => {
      const cmfData = [];
      for (let i = period - 1; i < data.length; i++) {
        let sumMoneyFlowVolume = 0;
        let sumVolume = 0;
        for (let j = i - period + 1; j <= i; j++) {
          const moneyFlowMultiplier = ((data[j].close - data[j].low) - (data[j].high - data[j].close)) / (data[j].high - data[j].low);
          const moneyFlowVolume = moneyFlowMultiplier * data[j].volume;
          sumMoneyFlowVolume += moneyFlowVolume;
          sumVolume += data[j].volume;
        }
        const cmf = sumVolume !== 0 ? sumMoneyFlowVolume / sumVolume : 0;
        cmfData.push({ time: data[i].time, value: cmf });
      }
      return cmfData;
    };

    const cmfData = calculateCMF(data);

    const cmfSeries = chart.addHistogramSeries({
      color: 'rgba(76, 175, 80, 0.5)',
      lineWidth: 2,
      priceFormat: {
        type: 'volume',
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    });
    cmfSeries.setData(cmfData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return (
    <div>
      <div ref={chartContainerRef} />
      <p className="mt-4">
        The Chaikin Money Flow (CMF) is a volume-weighted average of accumulation and distribution over a specified period, typically 20 or 21 days. It measures the amount of Money Flow Volume over a specific period. The indicator oscillates above and below the zero line. When the CMF is above zero, it indicates buying pressure, while values below zero indicate selling pressure. Traders use the CMF to confirm trends, identify potential reversals, and gauge the overall buying or selling pressure in the market.
      </p>
    </div>
  );
};

export default ChaikinMoneyFlowChart;