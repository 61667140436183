import React from 'react';

const OrderBook = ({ orderBook }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
      <div>
        <h3 className="text-xl font-semibold mb-2">Order Book - Bids</h3>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Size</th>
            </tr>
          </thead>
          <tbody>
            {orderBook.bids.slice(0, 10).map((bid, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{bid.px}</td>
                <td className="border px-4 py-2">{bid.sz}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">Order Book - Asks</h3>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="px-4 py-2">Price</th>
              <th className="px-4 py-2">Size</th>
            </tr>
          </thead>
          <tbody>
            {orderBook.asks.slice(0, 10).map((ask, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{ask.px}</td>
                <td className="border px-4 py-2">{ask.sz}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderBook;