import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { calculateMACD } from '../utils/indicators';

const MacdChart = ({ data, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
        horzLines: {
          color: 'rgba(197, 203, 206, 0.5)',
        },
      },
      crosshair: {
        mode: 1,
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals, 5), // Increased precision
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const macdData = calculateMACD(data, szDecimals, isSpot, actualDecimals);

    const macdLineSeries = chart.addLineSeries({ 
      color: 'blue', 
      lineWidth: 2,
      priceFormat: {
        type: 'price',
        precision: Math.max(szDecimals, actualDecimals, 5),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals, 5)),
      },
    });
    const signalLineSeries = chart.addLineSeries({ 
      color: 'red', 
      lineWidth: 2,
      priceFormat: {
        type: 'price',
        precision: Math.max(szDecimals, actualDecimals, 5),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals, 5)),
      },
    });
    const histogramSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'price',
        precision: Math.max(szDecimals, actualDecimals, 5),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals, 5)),
      },
      priceScaleId: '',
    });

    macdLineSeries.setData(macdData.macdLine);
    signalLineSeries.setData(macdData.signalLine);
    histogramSeries.setData(macdData.histogram);

    chart.applyOptions({
      localization: {
        priceFormatter: (price) => price.toFixed(Math.max(szDecimals, actualDecimals, 5)),
      },
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, isSpot, actualDecimals]);

  return <div ref={chartContainerRef} />;
};

export default MacdChart;