import React, { useEffect, useRef, useState, useCallback } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import { calculateLiquiditySwings } from '../utils/indicators';

const LiquiditySwingsChart = ({ data, timeframe, szDecimals, isSpot, actualDecimals }) => {
  const chartContainerRef = useRef();
  const tooltipRef = useRef();
  const [options, setOptions] = useState({
    length: 14,
    area: 'Wick Extremity',
    filterOptions: 'Count',
    filterValue: 0,
    showTop: true,
    showBottom: true,
    swingStrength: 'Strong',
  });
  const [hoveredSwing, setHoveredSwing] = useState(null);
  const [lockedSwing, setLockedSwing] = useState(null);
  const chartRef = useRef();
  const candleSeriesRef = useRef();
  const seriesRef = useRef({
    highSwings: null,
    lowSwings: null,
  });
  const markersRef = useRef([]);

  const initChart = useCallback(() => {
    if (data.length === 0) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      crosshair: { mode: CrosshairMode.Normal },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
        precision: Math.max(szDecimals, actualDecimals),
        minMove: 1 / Math.pow(10, Math.max(szDecimals, actualDecimals)),
      },
      timeScale: { borderColor: 'rgba(197, 203, 206, 1)' },
      localization: {
        priceFormatter: price => price.toFixed(Math.max(szDecimals, actualDecimals)),
      },
      customSeriesOptions: {
        candlestick: {
          markers: {
            useCustomHtml: true,
          },
        },
      },
    });

    chartRef.current = chart;

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#26a69a',
      downColor: '#ef5350',
      borderVisible: false,
      wickUpColor: '#26a69a',
      wickDownColor: '#ef5350',
    });

    candleSeriesRef.current = candleSeries;
    candleSeries.setData(data);

    // Create series for high and low swings
    seriesRef.current.highSwings = chart.addLineSeries({
      color: 'rgba(255, 0, 0, 1)',
      lineWidth: 2,
      priceLineVisible: false,
      lastValueVisible: false,
      crosshairMarkerVisible: false,
    });

    seriesRef.current.lowSwings = chart.addLineSeries({
      color: 'rgba(0, 255, 0, 1)',
      lineWidth: 2,
      priceLineVisible: false,
      lastValueVisible: false,
      crosshairMarkerVisible: false,
    });

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, szDecimals, actualDecimals]);

  useEffect(initChart, [initChart]);

  const updateChart = useCallback(() => {
    if (!chartRef.current || !candleSeriesRef.current) return;
  
    const chart = chartRef.current;
    const candleSeries = candleSeriesRef.current;
  
    const intrabarTf = getIntrabarTf(timeframe);
    const liquiditySwings = calculateLiquiditySwings(data, { ...options, intrabarTf });
    const allSwings = [...liquiditySwings.highSwings, ...liquiditySwings.lowSwings];
  
    const updateSeries = (seriesType, swings) => {
      if (seriesRef.current[seriesType]) {
        const swingLines = swings.flatMap((swing, index) => [
          { time: swing.time, value: swing.value },
          { time: swing.time + 0.0001, value: swing.bottom || swing.top },
        ]);
        seriesRef.current[seriesType].setData(swingLines);
      }
    };
  
    if (options.showTop) {
      updateSeries('highSwings', liquiditySwings.highSwings);
    } else {
      seriesRef.current.highSwings.setData([]);
    }
  
    if (options.showBottom) {
      updateSeries('lowSwings', liquiditySwings.lowSwings);
    } else {
      seriesRef.current.lowSwings.setData([]);
    }

    const getMarkerSize = (strength) => {
      switch (strength) {
        case 'Weak': return 1;
        case 'Strong': return 2;
        default: return 2; // Normal strength
      }
    };

    function getCustomHtml(swing, size) {
      return `<div style="
        width: ${size}px;
        height: ${size}px;
        background-color: ${swing.value === swing.high ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 255, 0, 1)'};
        border-radius: 50%;
        position: absolute;
        top: -${size / 2}px;
        left: -${size / 2}px;
      "></div>`;
    }

    const markers = allSwings
    .map(swing => ({
      time: swing.time,
      position: swing.value === swing.high ? 'aboveBar' : 'belowBar',
      color: swing.value === swing.high ? 'rgba(255, 0, 0, 1)' : 'rgba(0, 255, 0, 1)',
      shape: 'circle',
      size: getMarkerSize(swing.strength),
      text: ' ',
      customHtml: getCustomHtml(swing, getMarkerSize(swing.strength)),
    }))
    .sort((a, b) => a.time - b.time);

    markersRef.current = markers;
    candleSeries.setMarkers(markers);

    const handleCrosshairMove = (param) => {
      if (lockedSwing) return;

      if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > chartContainerRef.current.clientWidth || param.point.y < 0 || param.point.y > chartContainerRef.current.clientHeight) {
        tooltipRef.current.style.display = 'none';
        setHoveredSwing(null);
      } else {
        const nearestSwing = allSwings.reduce((nearest, current) => {
          const currentDiff = Math.abs(current.time - param.time);
          const nearestDiff = Math.abs(nearest.time - param.time);
          return currentDiff < nearestDiff ? current : nearest;
        });

        updateTooltip(param.point, nearestSwing);
        setHoveredSwing(nearestSwing);
      }
    };

    const handleClick = (param) => {
      if (param.time) {
        const clickedSwing = allSwings.find(swing => swing.time === param.time);
        if (clickedSwing) {
          setLockedSwing(prevLocked => prevLocked && prevLocked.time === clickedSwing.time ? null : clickedSwing);
        } else {
          setLockedSwing(null);
        }
      } else {
        setLockedSwing(null);
      }
    };

    chart.unsubscribeCrosshairMove(handleCrosshairMove);
    chart.subscribeCrosshairMove(handleCrosshairMove);

    chart.unsubscribeClick(handleClick);
    chart.subscribeClick(handleClick);

  }, [data, options, timeframe]);

  useEffect(updateChart, [updateChart]);

  const updateTooltip = useCallback((point, swing) => {
    if (!tooltipRef.current) return;

    const toolTipWidth = 150;
    const toolTipHeight = 120;
    const toolTipMargin = 15;

    let left = point.x + toolTipMargin;
    let top = point.y + toolTipMargin;

    if (left > chartContainerRef.current.clientWidth - toolTipWidth - toolTipMargin) {
      left = chartContainerRef.current.clientWidth - toolTipWidth - toolTipMargin;
    }

    if (top > chartContainerRef.current.clientHeight - toolTipHeight - toolTipMargin) {
      top = chartContainerRef.current.clientHeight - toolTipHeight - toolTipMargin;
    }

    tooltipRef.current.style.left = `${left}px`;
    tooltipRef.current.style.top = `${top}px`;
    tooltipRef.current.style.display = 'block';

    tooltipRef.current.innerHTML = `
      <div>Type: ${swing.value === swing.high ? 'High' : 'Low'} Swing</div>
      <div>Time: ${new Date(swing.time * 1000).toLocaleString()}</div>
      <div>Value: ${swing.value.toFixed(Math.max(szDecimals, actualDecimals))}</div>
      <div>Volume: ${swing.volume.toFixed(2)}</div>
      <div>Count: ${swing.count}</div>
      <div>TD: ${swing.tdCount} (${swing.tdDirection})</div>
      <div>ATR: ${swing.atr.toFixed(2)}</div>
    `;
  }, [szDecimals, actualDecimals]);

  const getIntrabarTf = (tf) => {
    switch (tf) {
      case '1d': return '1h';
      case '4h': return '15m';
      case '1h': return '5m';
      case '30m': return '3m';
      case '15m': return '1m';
      default: return '1m';
    }
  };

  const displayedSwing = lockedSwing || hoveredSwing;

  return (
    <div className="liquidity-swings-chart">
      <div className="mb-4 flex flex-wrap gap-2">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={options.showTop}
            onChange={(e) => setOptions({ ...options, showTop: e.target.checked })}
            className="mr-2"
          />
          Show High Swings
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={options.showBottom}
            onChange={(e) => setOptions({ ...options, showBottom: e.target.checked })}
            className="mr-2"
          />
          Show Low Swings
        </label>
        <select
          value={options.area}
          onChange={(e) => setOptions({ ...options, area: e.target.value })}
          className="border rounded px-2 py-1"
        >
          <option value="Wick Extremity">Wick Extremity</option>
          <option value="Full Range">Full Range</option>
        </select>
        <select
          value={options.filterOptions}
          onChange={(e) => setOptions({ ...options, filterOptions: e.target.value })}
          className="border rounded px-2 py-1"
        >
          <option value="Count">Filter by Count</option>
          <option value="Volume">Filter by Volume</option>
        </select>
        <input
          type="number"
          value={options.filterValue}
          onChange={(e) => setOptions({ ...options, filterValue: parseInt(e.target.value) })}
          className="border rounded px-2 py-1 w-20"
        />
        <select
          value={options.swingStrength}
          onChange={(e) => setOptions({ ...options, swingStrength: e.target.value })}
          className="border rounded px-2 py-1"
        >
          <option value="Weak">Weak</option>
          <option value="Normal">Normal</option>
          <option value="Strong">Strong</option>
        </select>
      </div>
      <div className="relative">
        <div ref={chartContainerRef} />
        <div
          ref={tooltipRef}
          className="absolute bg-white border border-gray-300 p-2 rounded shadow-lg"
          style={{ display: 'none', width: '150px', zIndex: 1000 }}
        />
      </div>
      {displayedSwing && (
        <div className="mt-4 p-4 bg-gray-100 rounded">
          <h4 className="font-bold">{displayedSwing.value === displayedSwing.high ? 'High' : 'Low'} Swing Details {lockedSwing ? '(Locked)' : ''}</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <p>Type: {displayedSwing.value === displayedSwing.high ? 'High' : 'Low'} Swing</p>
            <p>Value: {displayedSwing.value.toFixed(Math.max(szDecimals, actualDecimals))}</p>
            <p>Volume: {displayedSwing.volume.toFixed(2)}</p>
            <p>Count: {displayedSwing.count}</p>
            <p>TD: {displayedSwing.tdCount} ({displayedSwing.tdDirection})</p>
            <p>ATR: {displayedSwing.atr.toFixed(2)}</p>
            <p>Strength: {displayedSwing.strength}</p>
          </div>
        </div>
      )}
      <div className="mt-4 p-4">
        <p>The "Count" refers to the number of subsequent candles that interact with the swing level.</p>
        <ul className="list-disc pl-5">
          <li>For a high swing: It's the number of candles after the swing point where the low is below the swing high.</li>
          <li>For a low swing: It's the number of candles after the swing point where the high is above the swing low.</li>
        </ul>
        <p className="mt-2">A higher count indicates that more candles have interacted with this swing level, suggesting it might be a more significant level of support or resistance. For example, a count of 11 means that 11 candles after the swing point have touched or crossed this level, while a count of 2 means only 2 candles have done so.</p>
      </div>
    </div>
  );
};

export default LiquiditySwingsChart;