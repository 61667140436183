import React from 'react';

const SupportResistanceLevels = ({ levels, szDecimals, isSpot }) => {
  const formatNumber = (num) => {
    if (typeof num !== 'number') return 'N/A';
    const maxDecimals = isSpot ? 8 : 6;
    const allowedDecimals = Math.min(maxDecimals - (szDecimals || 0), 5);
    return num.toFixed(allowedDecimals);
  };

  if (!levels || (!levels.support && !levels.resistance)) {
    return <div>No support/resistance levels available</div>;
  }

  return (
    <div className="bg-white shadow-md rounded p-4 mb-8 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <h3 className="text-xl font-semibold mb-2">Support Levels</h3>
        <div className="grid grid-cols-2 gap-4">
          {levels.support && levels.support.map((level, index) => (
            <div key={index}>
              <h4 className="text-sm font-semibold text-gray-500">Level {index + 1}</h4>
              <p className="text-lg font-bold">{formatNumber(level)}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h3 className="text-xl font-semibold mb-2">Resistance Levels</h3>
        <div className="grid grid-cols-2 gap-4">
          {levels.resistance && levels.resistance.map((level, index) => (
            <div key={index}>
              <h4 className="text-sm font-semibold text-gray-500">Level {index + 1}</h4>
              <p className="text-lg font-bold">{formatNumber(level)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportResistanceLevels;