// src/components/ATRChart.js
import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const ATRChart = ({ data, atrData }) => {
  const chartContainerRef = useRef();

  useEffect(() => {
    if (data.length === 0 || !atrData) return;

    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: 300,
      layout: {
        backgroundColor: '#ffffff',
        textColor: 'rgba(33, 56, 77, 1)',
      },
      grid: {
        vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
        horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 1)',
      },
    });

    const atrSeries = chart.addLineSeries({ color: 'orange', lineWidth: 2 });
    atrSeries.setData(atrData);

    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [data, atrData]);

  return <div ref={chartContainerRef} />;
};

export default ATRChart;